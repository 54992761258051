import React from "react";
import "./meni.scss";
import Logo from "./img/s2sLogo.png";
import { useState } from "react";
import Modal from "./MeniModal.jsx";
//import { HashLink } from "react-router-hash-link";

const Meni = () => {
  const [openModal, setOpenModal] = useState(false);
  const ClickHander = function () {
    setOpenModal(true);
  };

  return (
    <div className="mainMeni">
      <div className="meni_container">
        <div className="meni-item">
          <a href="/">Početna</a>
        </div>
        <div className="meni-item">
          <a href="/#/Radionice">Radionice</a>
          {/* <HashLink to="/Radionice">Radionice</HashLink> */}
        </div>

        <div className="meni-item">
          <a href="/#/Panel">Panel</a>
        </div>
      </div>
      <div className="meni_logo">
        <a href="/">
          <img src={Logo}></img>
        </a>
      </div>
      <div className="meni_container">
        <div className="meni-item">
          <a href="/#/Agenda">Agenda</a>
        </div>
        <div className="meni-item">
          <a href="/#/FAQ">FAQ</a>
        </div>
        <div className="meni-item">
          <a href="/#/Partneri">Partneri</a>
        </div>
        {/* <div className="meni-item_prijava">
          <a href="/#/Prijave">Prijavi se</a>
        </div> */}
        {/* <button className="btn" onClick={() => setOpenModal(true)}></button> */}
      </div>
      <div className="burgerMenuIcon" onClick={ClickHander}>
        <div className="bugerMenuLine"></div>
        <div className="bugerMenuLine"></div>
        <div className="bugerMenuLine"></div>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)} />
    </div>
  );
};

export default Meni;
