import React from "react";
import "./footer.scss";
import Chat from "./img/footer/chat1.png";
import Facebook from "./img/footer/facebook1.png";
import FonisLogo from "./img/footer/fonisLogoFooter.png";
import Instagram from "./img/footer/instagram1.png";
import Linkedin from "./img/footer/linkedin1.png";
import Location from "./img/footer/location1.png";
import Website from "./img/footer/website1.png";

const Footer = () => {
  return (
    <div className="footercontainer">
      <div className="ft_links">
        <div className="ft_site">
          <a href="https://www.fonis.rs">
            <img src={Website} alt="Fonis.rs" />
            <p>fonis.rs</p>
          </a>
        </div>
        <div className="ft_socialmedia">
          <a href="https://www.instagram.com/fonis_fon/">
            <img src={Instagram} />{" "}
          </a>
          <a href="https://www.facebook.com/fonis.rs/?locale=sr_RS">
            <img src={Facebook} />{" "}
          </a>
          <a href="https://www.linkedin.com/company/fonis/">
            <img src={Linkedin} />
          </a>
          <a href="/#/FAQ#faq_pitanja">
            <img src={Chat} />
          </a>
        </div>
      </div>
      <div className="ft_logo">
        <a href="https://www.fonis.rs">
          <img src={FonisLogo} alt="FonisLogo" />
        </a>
      </div>
      <div className="ft_location">
        <a href="https://goo.gl/maps/PUiTu7bB11hHnyvG8">
          <p>
            Fakultet organizacionih nauka <br />
            Jove Ilića 154, Beograd
          </p>
          <img src={Location} alt=""></img>
        </a>
      </div>
    </div>
  );
};

export default Footer;
