import React from "react";
import { AnimatePresence, motion } from "framer-motion";

const FAQOdgovor = ({ open, onClose, odgovor }) => {
 // if (!open) return null;
return (
  <AnimatePresence> {open  && (
  <motion.div
  initial={{ opacity: 0, height: 0 }}
  animate={{ opacity: 1, height: "auto" }}
  exit =  {{ height: 0, opacity: 0, transition: { duration: 0.5 }}}
  transition={{ ease: "easeIn", duration: 0.5 }}
  style={{ marginTop: "1rem" }}
  >{odgovor}
  </motion.div>)}
  </AnimatePresence>

  )
};

export default FAQOdgovor;
