import React from "react";
import { useEffect, useState } from "react";
import svetli from "./Slike/svetliCutout.png";
import tamni from "./Slike/tamniCutout.png";
import beliHexagon from "./Slike/beliHexagon.png";
import placeHolder from "./Slike/placeHolder.png";
import placeHolder1 from "./Slike/placeHolder1.png";
import Footer from "./../../Components/Footer";
import Fon from "./Slike/Godisnji/Fon_Godisnji.png";
import Guarana from "./Slike/Godisnji/Guarana_Godisnji.png";
import HrFabrika from "./Slike/Godisnji/HrFabrika_Godisnji.png";
import Prime from "./Slike/Godisnji/Prime_Godisnji.png";
import Sbg from "./Slike/Godisnji/SBG_Godisnji.png";
import Unija from "./Slike/Godisnji/Unija_Godisnji.png";

import BazaZnanja from "./Slike/Robni/BazaZnanja_Robni.png";
import Djumbir from "./Slike/Robni/Djumbir_Robni.png";
import GamePub from "./Slike/Robni/GamePub_Robni.png";
import Jafa from "./Slike/Robni/Jafa_Robni.png";
import Kampster from "./Slike/Robni/Kampster_Robni.png";
import Milford from "./Slike/Robni/Milford_Robni.png";
import NauciDizajn from "./Slike/Robni/NauciDizajn_Robni.png";
import ObratiPaznju from "./Slike/Robni/ObratiPaznju_Robni.png";
import Oksford from "./Slike/Robni/Oksford_Robni.png";
import SlikajCirkaj from "./Slike/Robni/SlikajCirkaj_Robni.png";
import Sofs from "./Slike/Robni/Sofs_Robni.png";
import BeerPong from "./Slike/Robni/BeerPong_Robni.png";
import Jdp from "./Slike/Robni/JDP_Robni.png";
import Portal from "./Slike/Robni/Portal_Robni.png";
import SwimmingAdventures from "./Slike/Robni/SwimmingAdventures_Robni.png";
import Una from "./Slike/Robni/Una_Robni.png";
import Akademska from "./Slike/Robni/Akademska_Robni.png";
import Babylon from "./Slike/Robni/Babylon_Robni.png";
import Boom from "./Slike/Robni/Boom_Robni.png";
import Domaci from "./Slike/Robni/Domaci_Robni.png";
import Go2 from "./Slike/Robni/Go2_Robni.png";
import GoldenRoses from "./Slike/Robni/GoldenRoses_Robni.png";
import Atlantic from "./Slike/Robni/Atlantic_Robni.png";
import Gamboca from "./Slike/Robni/Gamboca_Robni.png";
import Lda from "./Slike/Robni/LDA_Robni.png";
import Miss from "./Slike/Robni/Miss_Robni.png";
import Nescafe from "./Slike/Robni/Nescafe_Robni.png";
import Selfi from "./Slike/Robni/Selfi_Robni.png";
import Zlata from "./Slike/Robni/Zlata_Robni.png";
import Divadjo from "./Slike/Robni/Divadjo_Robni.png";

import Asocijacija from "./Slike/Medijski/Asocijacija_Medijski.png";
import DebatniKlub from "./Slike/Medijski/DebatniKlub_Medijski.png";
import Joombooz from "./Slike/Medijski/Joombooz_Medijski.png";
import RadioAS from "./Slike/Medijski/RadioAS_Medijski.png";
import RockRadio from "./Slike/Medijski/RockRadio_Medijski.png";
import Sbu from "./Slike/Medijski/SBU_Medijski.png";
import Znanje from "./Slike/Medijski/Znanje_Medijski.png";
import Best from "./Slike/Medijski/Best_Medijski.png";
import CaseStudy from "./Slike/Medijski/CaseStudy_Medijski.png";
import Centar from "./Slike/Medijski/Centar_Medijski.png";
import CodeWithMary from "./Slike/Medijski/CodeWithMary_Medijski.png";
import Czr from "./Slike/Medijski/Czr_Medijski.png";
import Elab from "./Slike/Medijski/Elab_Medijski.png";
import Karijera from "./Slike/Medijski/Karijera_Robni.png";
import MatchIT from "./Slike/Medijski/MatchIT_Medijski.png";
import Quality from "./Slike/Medijski/Quality_Robni.png";
import Sefa from "./Slike/Medijski/Sefa_Medijski.png";
import Smart from "./Slike/Medijski/Smart_Robni.png";
import ZnamJa from "./Slike/Medijski/ZnamJa_Medijski.png";

const Cutoutovi = () => {
  const imagesLevo = [Fon, Guarana, HrFabrika, Prime, Sbg, Unija]; //niz slika levog heksagona

  const imagesSrednje = [
    Asocijacija,
    DebatniKlub,
    Joombooz,
    RadioAS,
    RockRadio,
    Sbu,
    Znanje,
    Best,
    CaseStudy,
    Centar,
    CodeWithMary,
    Czr,
    Elab,
    Karijera,
    MatchIT,
    Quality,
    Sefa,
    Smart,
    ZnamJa,
  ]; //niz slika srednjeg heksagona

  const imagesDesno = [
    BazaZnanja,
    Djumbir,
    GamePub,
    Jafa,
    Kampster,
    Milford,
    NauciDizajn,
    ObratiPaznju,
    Oksford,
    SlikajCirkaj,
    Sofs,
    BeerPong,
    Jdp,
    Portal,
    SwimmingAdventures,
    Una,
    Akademska,
    Babylon,
    Boom,
    Domaci,
    Go2,
    GoldenRoses,
    Atlantic,
    Gamboca,
    Lda,
    Miss,
    Nescafe,
    Selfi,
    Zlata,
    Divadjo,
  ]; //niz slika desnog heksagona

  const [currentImageLevo, setCurrentImageLevo] = useState(Fon); //use State fja koja na pocetku postavlja neku sliku
  const [currentImageSrednje, setCurrentImageSrednje] = useState(Asocijacija);
  const [currentImageDesno, setCurrentImageDesno] = useState(BazaZnanja);

  function GodisnjiFja() {
    if (currentImageLevo === Guarana) {
      window.open("https://www.guarana.rs", "_blank");
    } else if (currentImageLevo === Fon) {
      window.open("https://www.fon.bg.ac.rs", "_blank");
    } else if (currentImageLevo === HrFabrika) {
      window.open("https://hrfabrika.com/sr/pocetna/", "_blank");
    } else if (currentImageLevo === Prime) {
      window.open("https://www.primeholding.com/", "_blank");
    } else if (currentImageLevo === Sbg) {
      window.open(
        "https://www.youtube.com/watch?v=tXbH4H3P7vI&ab_channel=SBGAkademija",
        "_blank"
      );
    } else if (currentImageLevo === Unija) {
      window.open("https://unija.com/sr/", "_blank");
    }
  }

  function MedijskiFja() {
    if (currentImageSrednje === Fon) {
      window.open("https://www.fon.bg.ac.rs", "_blank");
    } else if (currentImageSrednje === Asocijacija) {
      window.open("http://safon.fon.bg.ac.rs/", "_blank");
    } else if (currentImageSrednje === DebatniKlub) {
      window.open("https://www.instagram.com/_dkfon_/?hl=en", "_blank");
    } else if (currentImageSrednje === Joombooz) {
      window.open("https://www.joombooz.com/", "_blank");
    } else if (currentImageSrednje === RadioAS) {
      window.open("https://radioas.fm/", "_blank");
    } else if (currentImageSrednje === RockRadio) {
      window.open("https://rockradio.rs/", "_blank");
    } else if (currentImageSrednje === Sbu) {
      window.open("https://sbu-poslovi.rs/", "_blank");
    } else if (currentImageSrednje === Znanje) {
      window.open("http://www.znanje.rs/", "_blank");
    } else if (currentImageSrednje === Best) {
      window.open("https://best.rs/", "_blank");
    } else if (currentImageSrednje === CaseStudy) {
      window.open("https://casestudyclub.org/en/", "_blank");
    } else if (currentImageSrednje === Centar) {
      window.open("https://www.fonovcentar.rs/", "_blank");
    } else if (currentImageSrednje === CodeWithMary) {
      window.open(
        "https://instagram.com/codewithmary?igshid=MzRlODBiNWFlZA==",
        "_blank"
      );
    } else if (currentImageSrednje === Czr) {
      window.open("https://czrkff.rs/", "_blank");
    } else if (currentImageSrednje === Elab) {
      window.open("https://elab.fon.bg.ac.rs/", "_blank");
    } else if (currentImageSrednje === Karijera) {
      window.open("http://www.razvojkarijere.bg.ac.rs/", "_blank");
    } else if (currentImageSrednje === MatchIT) {
      window.open("https://matchit.rs/", "_blank");
    } else if (currentImageSrednje === Quality) {
      window.open(
        "https://www.instagram.com/qualitymediastation/?hl=en",
        "_blank"
      );
    } else if (currentImageSrednje === Sefa) {
      window.open("https://www.sefa.org.rs/", "_blank");
    } else if (currentImageSrednje === Smart) {
      window.open("https://www.smartconsulting-agency.com/", "_blank");
    } else if (currentImageSrednje === ZnamJa) {
      window.open(
        "https://instagram.com/znam_ja_ko_si_ti?igshid=MzRlODBiNWFlZA==",
        "_blank"
      );
    }
  }

  function RobniFja() {
    if (currentImageDesno === BazaZnanja) {
      window.open("https://bazaznanja.rs/general/pocetna", "_blank");
    } else if (currentImageDesno === Djumbir) {
      window.open("https://djumbirsuperfoodbar.rs/", "_blank");
    } else if (currentImageDesno === GamePub) {
      window.open("https://gamepub.rs/", "_blank");
    } else if (currentImageDesno === Jafa) {
      window.open("https://www.jaffa.rs/", "_blank");
    } else if (currentImageDesno === Kampster) {
      window.open("https://www.thecampster.com/rs/", "_blank");
    } else if (currentImageDesno === Milford) {
      window.open("https://www.instagram.com/milford_srbija/?hl=en", "_blank");
    } else if (currentImageDesno === NauciDizajn) {
      window.open("https://www.naucidizajn.com/", "_blank");
    } else if (currentImageDesno === ObratiPaznju) {
      window.open("https://www.instagram.com/pabkviz_obratipaznju/", "_blank");
    } else if (currentImageDesno === Oksford) {
      window.open(
        "https://www.akademijaoxford.com/?gclid=CjwKCAjw38SoBhB6EiwA8EQVLtpAzx6sdLRjCnrJaKmxBE7k8J56yquJYXYGswXudv9KmT4HJefv_BoCbVkQAvD_BwE",
        "_blank"
      );
    } else if (currentImageDesno === SlikajCirkaj) {
      window.open("https://slikajicirkaj.com/", "_blank");
    } else if (currentImageDesno === Sofs) {
      window.open(
        "https://www.behance.net/search/projects/?search=SOFs",
        "_blank"
      );
    } else if (currentImageDesno === BeerPong) {
      window.open(
        "https://www.instagram.com/beerpongbar_belgrade/?hl=en",
        "_blank"
      );
    } else if (currentImageDesno === Jdp) {
      window.open("https://www.jdp.rs/rs/", "_blank");
    } else if (currentImageDesno === Portal) {
      window.open("https://portalgamingworld.com/", "_blank");
    } else if (currentImageDesno === SwimmingAdventures) {
      window.open("https://www.plivackeavanture.com/", "_blank");
    } else if (currentImageDesno === Una) {
      window.open(
        "https://www.instagram.com/unaas_customs/?igshid=MWZjMTM2ODFkZg%3D%3D",
        "_blank"
      );
    } else if (currentImageDesno === Akademska) {
      window.open("https://akademska-misao.rs/", "_blank");
    } else if (currentImageDesno === Babylon) {
      window.open("https://babylonparkbeograd.rs/", "_blank");
    } else if (currentImageDesno === Boom) {
      window.open("https://www.boombox.eu/rs/", "_blank");
    } else if (currentImageDesno === Domaci) {
      window.open(
        "https://instagram.com/domaci_ketering_danijela_?igshid=MWZjMTM2ODFkZg==",
        "_blank"
      );
    } else if (currentImageDesno === Go2) {
      window.open("https://go2travelling.net/", "_blank");
    } else if (currentImageDesno === GoldenRoses) {
      window.open("https://www.instagram.com/goldenroses.rs/?hl=en", "_blank");
    } else if (currentImageDesno === Nescafe) {
      window.open("https://www.nescafe.com/rs/", "_blank");
    } else if (currentImageDesno === Atlantic) {
      window.open("https://www.atlanticgrupa.com/hr/", "_blank");
    } else if (currentImageDesno === Gamboca) {
      window.open(
        "https://www.instagram.com/gomboca_ketering/?hl=en",
        "_blank"
      );
    } else if (currentImageDesno === Lda) {
      window.open(
        "https://www.facebook.com/plesnaakademijalazareski/?paipv=0&eav=Afb1m0cLEumJ9AYBO142DEJeTsXtHUNmaJDC_KtBCrDUCdXf1LC9ONMcdSwhiODyOzg&_rdr",
        "_blank"
      );
    } else if (currentImageDesno === Miss) {
      window.open("https://www.instagram.com/miss_futrolica/", "_blank");
    } else if (currentImageDesno === Selfi) {
      window.open("https://selfiemuseum.rs/", "_blank");
    } else if (currentImageDesno === Zlata) {
      window.open("https://www.poslasticarnicazlata.com/", "_blank");
    } else if (currentImageDesno === Divadjo) {
      window.open("https://www.instagram.com/konjicki_klub_divadjo/", "_blank");
    }
  }

  let brojacL = 0;
  let brojacS = 0;
  let brojacD = 0;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (brojacL == imagesLevo.length - 1) {
        brojacL = 0;
      } else {
        brojacL++;
      }

      if (brojacS == imagesSrednje.length - 1) {
        brojacS = 0;
      } else {
        brojacS++;
      }

      if (brojacD == imagesDesno.length - 1) {
        brojacD = 0;
      } else {
        brojacD++;
      }

      setCurrentImageLevo(
        imagesLevo[brojacL]
        //imagesLevo[Math.floor(Math.random() * imagesLevo.length)]
      );
      setCurrentImageSrednje(
        imagesSrednje[brojacS]
        //imagesSrednje[Math.floor(Math.random() * imagesSrednje.length)]
      );
      setCurrentImageDesno(
        imagesDesno[brojacD]
        //imagesDesno[Math.floor(Math.random() * imagesDesno.length)]
      );
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="mega-container par_id">
      <div className="beli-hexagon-container">
        {/* <div class="hexagon">
          <img src={Fon} />
        </div> */}

        <div>
          <h2>GODIŠNJI PARTNERI</h2>
          <img
            onClick={GodisnjiFja}
            id="godisnji-slika"
            src={currentImageLevo}
            alt="beli heksagon"
          ></img>
        </div>

        <div>
          <h2>MEDIJSKI PARTNERI</h2>
          <img
            onClick={MedijskiFja}
            id="medijski-slika"
            src={currentImageSrednje}
            alt="beli heksagon"
          ></img>
        </div>

        <div>
          <h2>ROBNI PARTNERI</h2>
          <img
            id="robni-slika"
            onClick={RobniFja}
            src={currentImageDesno}
            alt="beli heksagon"
          ></img>
        </div>
      </div>

      <div className="cutout-holder">
        <img className="svetli-cutout" src={svetli} alt="cutout"></img>
        <img className="tamni-cutout" src={tamni} alt="cutout"></img>
      </div>

      <footer className="footer-container">
        <Footer></Footer>
      </footer>
    </div>
  );
};

export default Cutoutovi;
