import React from "react";
import "./OPanelisti.scss";
import { AnimatePresence, motion } from "framer-motion";

const OPanelisti = ({ open, ime, opis, slika, kompanija }) => {
  //if (!open) return null;
  //if (!open) return null;
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className="OPanelisti"
          initial={{ opacity: 0, x: -100, height: 0 }}
          animate={{ opacity: 1, x: 0, height: "auto" }}
          exit={{ opacity: 0, x: -100, height: 0 }}
          transition={{ ease: "easeIn", duration: 0.7 }}
        >
          <motion.div
            className="pnl_levideo"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
            transition={{ ease: "easeIn", duration: 1 }}
          >
            <h1>{ime}</h1>
            <img src={slika} />
            <p>{kompanija}</p>
          </motion.div>
          <motion.div
            className="pnl_opis"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
            transition={{ ease: "easeIn", duration: 1 }}
          >
            <p>{opis}</p>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OPanelisti;
