import React from "react";
import "./OPanelistiTelefon.scss";

const OPanelistiTelefon = ({ slika, ime, kompanija, opis, funkcija }) => {
  return (
    <div className="OPanelistiTelefon" onClick={funkcija}>
      <h1>{ime}</h1>
      <img src={slika} />
      <p>{kompanija}</p>
      <hr className="pnl_hr" />
      <p className="pnl_tlfOpis">{opis}</p>
    </div>
  );
};

export default OPanelistiTelefon;
