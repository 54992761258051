import React from "react";
import Kore from "./img/kore.png";

const VelikiKrug = (props) => {
  //const [slika, setSlika] = useState();

  return (
    <div className="veliki-krug-tamni">
      <div className="veliki-krug-svetli">
        <div className="veliki-krug-beli">
          <img
            className={`veliki-krug-beli  ${props.idSlike}`}
            id="velika-slika-funkcija"
            src={Kore}
          ></img>
          <p className="veliki-krug-hover">{props.tekst}</p>
        </div>
      </div>
    </div>
  );
};

export default VelikiKrug;
