import React from "react";
import Meni from "../../Components/Meni";
import "./PartneriCss.scss";
import MainBody from "./MainBody";
import { useEffect } from "react";

const Partneri = () => {
  useEffect(() => {
    document.title = "Partneri";
    setTimeout(() => {
      console.log(document.getElementsByClassName("par_id"));
      document.getElementsByClassName("par_id")[0].style.opacity = "1";
      document.getElementsByClassName("par_id")[1].style.opacity = "1";
      document.getElementsByClassName("par_id")[2].style.opacity = "1";
      document.getElementsByClassName("par_id")[3].style.opacity = "1";
    }, 300);
  }, []);
  return (
    <div className="partneri-container">
      <Meni></Meni>
      <MainBody></MainBody>
    </div>
  );
};

export default Partneri;
