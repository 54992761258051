import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import FAQOdgovor from "./FAQOdgovor";
import "./FAQ.scss";
import Meni from "../../Components/Meni";
import munjica1 from "./slike/Vector 11.png";
import munjica2 from "./slike/Vector 8.png";
import Footer from "../../Components/Footer";
import Zavesa from "./slike/radionice-faq-panel-DESKTOP-GORE.png";
import DonjaSlika from "./slike/DESKTOP-DOLE.png";
import FAQPitanje from "./FAQPitanje";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import Odgovor from "../Prijave/Odgovor";
const FAQ = () => {
  const [openModal, setOpenModal] = useState(false);
  const [textOdgovor, setTextOdgovor] = useState("");
  const email = useRef(null);
  const pitanje = useRef(null);

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  useEffect(() => {
    document.title = "FAQ";
    setTimeout(function () {
      document.getElementById("faq_id").style.opacity = 1;
    }, 300);
  }, []);
  const SendEmail = function () {
    if (!validateEmail(email.current.value)) {
      setTextOdgovor("Mail nije u ispravnom formatu");
      setOpenModal(true);
      return;
    }
    if (pitanje == null || pitanje.current.value == "") {
      setTextOdgovor("Molimo unesite pitanje");
      setOpenModal(true);
      return;
    }
    var templateParams = {
      from_name: email.current.value,
      message: pitanje.current.value,
    };
    emailjs
      .send(
        "service_9v0rtpy",
        "template_8rvulk9",
        templateParams,
        "5JbH27S6o0kgTO3Ao"
      )
      .then(
        function (response) {
          setTextOdgovor("Mail uspešno poslat");
          setOpenModal(true);
          email.current.value = "";
          pitanje.current.value = "";
        },
        function (error) {
          setTextOdgovor("Došlo je do greške prilikom slanja");
          setOpenModal(true);
        }
      );
  };
  return (
    <div className="faq">
      <Meni />
      <div className="faq_zavesa">
        <p className="faq_naslov">FAQ</p>
        <img src={Zavesa} />
      </div>
      <div className="faq_hero" id="faq_id">
        <div className="faq_pitanja">
          <FAQPitanje
            tekst="Ko se može prijaviti za učešće na S2S-u?"
            odgovor=" Za učešće na S2S-u mogu se prijaviti studenti bilo kog fakulteta u Srbiji koji imaju želju da steknu nova znanja i veštine."
          />
          <FAQPitanje
            tekst="Da li je neophodno da se donese laptop na radionice?"
            odgovor={[
              "Za određene radionice na S2S-u nije neophodno da se donese laptop, jer se održavaju u računarskim salama, dok je kod onih koje nisu u tim salama neophodno. Više informacija o tome možete pročitati u sekciji sajta ",
              <a href="/#/Radionice">radionice.</a>,
            ]}
          />
          <FAQPitanje
            tekst="Da li je moguće prisustvo na više radionica?"
            odgovor="U okviru prijave za učešće na S2S-u studenti biraju dve radionice, od kojih će biti primljeni na jednu. Nije moguće prisustvovati na više radionica, s obzirom na to da se odvijaju u isto vreme."
          />
          <FAQPitanje
            tekst="Na koji način se prijavljuje za učešće na S2S-u?"
            odgovor={[
              "Prijavljivanje se vrši popunjavanjem forme na sajtu. ",
              <a href="/#/Prijave">Prijavi se.</a>,
            ]}
          />
          <FAQPitanje
            tekst="Da li je potrebno predznanje za prisustvo radionicama?"
            odgovor={[
              "Za određene radionice na projektu je potrebno predznanje, dok neke to ne zahtevaju jer se polazi od osnovnih koncepata u okviru određene teme. Više informacija o tome možete pročitati u sekciji sajta ",
              <a href="/#/Radionice">radionice.</a>,
            ]}
          />
          <FAQPitanje
            tekst="Da li je moguće prisustvovati i panel diskusiji i radionici?"
            odgovor="Da, moguće je prisustvovati na oba događaja."
          />
          <FAQPitanje
            tekst="Da li je za učešće na projektu neophodno poslati CV?"
            odgovor="Slanje CV-a nije obavezno, ali ga zainteresovani studenti mogu priložiti."
          />
        </div>
        {/* <div className="faq_forma">
          <div className="faq_forma_naslov"></div>
          <div className="faq_forma_linija"></div>
          <div className="faq_forma_mail_tekst"></div>
          <div className="faq_forma_mail"></div>
          <div className="faq_forma_pitanje_tekst"></div>
          <div className="faq_forma_pitanje"></div>
        </div> */}
        {/* <div className="faq-pitanjacontainer">
          <ul className="faq-pitanja">
            <li onClick={ClickHander}>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
              <img src={munjica1} alt="" className="faq-munja" />
              <img src={munjica2} alt="" className="faq-munja2" />
              <FAQOdgovor
                open={openModal}
                onClose={() => setOpenModal(false)}
              />
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
              <img src={munjica1} alt="" className="faq-munja" />
              <img src={munjica2} alt="" className="faq-munja2" />
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
              <img src={munjica1} alt="" className="faq-munja" />
              <img src={munjica2} alt="" className="faq-munja2" />
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
              <img src={munjica1} alt="" className="faq-munja" />
              <img src={munjica2} alt="" className="faq-munja2" />
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
              <img src={munjica1} alt="" className="faq-munja" />
              <img src={munjica2} alt="" className="faq-munja2" />
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
              <img src={munjica1} alt="" className="faq-munja" />
              <img src={munjica2} alt="" className="faq-munja2" />
            </li>
          </ul>
        </div> */}
        <div className="faq-formacontainer">
          <div className="faq-forma">
            <ul className="faq-listaforma">
              <li className="faq-naslovforme">
                <p>Postavite nam pitanje</p>
              </li>
              <li className="faq-nazivpolja" id="faq_pitanja">
                <p>Mejl</p>
                <div className="faq-mejlcontainer">
                  <input
                    type="text"
                    id="textbox"
                    name="textbox"
                    className="faq-textbox1"
                    placeholder=""
                    ref={email}
                  ></input>
                </div>
              </li>
              <li className="faq-nazivpoljap">
                <p>Pitanje</p>
                <div className="faq-drugicontainer">
                  <textarea
                    ref={pitanje}
                    name="pitanje"
                    id="textoblast"
                    cols="35"
                    rows="10"
                    className="faq-textbox2"
                    placeholder="Postavite vaše pitanje"
                  ></textarea>
                </div>
              </li>
              <li className="faq-dugme">
                <div className="faq-dugmecontainer" onClick={SendEmail}>
                  <p>POŠALJI</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* </div> */}
        {/* <div className="faq-hero">
        <div className="faq-herogore">
          <div className="faq-zavesa">
            <img src={Zavesa} />
          </div>
          <div className="faq-gornjicontaier">
            <div className="faq-naziv">
              <p>FAQ</p>
            </div>
            <div className="faq-pitanjacontainer">
              <ul className="faq-pitanja">
                <li>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
                  <img src={munjica1} alt="" className="faq-munja" />
                  <img src={munjica2} alt="" className="faq-munja2" />
                </li>
                <li>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
                  <img src={munjica1} alt="" className="faq-munja" />
                  <img src={munjica2} alt="" className="faq-munja2" />
                </li>
                <li>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
                  <img src={munjica1} alt="" className="faq-munja" />
                  <img src={munjica2} alt="" className="faq-munja2" />
                </li>
                <li>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
                  <img src={munjica1} alt="" className="faq-munja" />
                  <img src={munjica2} alt="" className="faq-munja2" />
                </li>
                <li>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
                  <img src={munjica1} alt="" className="faq-munja" />
                  <img src={munjica2} alt="" className="faq-munja2" />
                </li>
                <li>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.{" "}
                  <img src={munjica1} alt="" className="faq-munja" />
                  <img src={munjica2} alt="" className="faq-munja2" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="faq-herodole">
          <div className="faq-formacontainer">
            <div className="faq-forma">
              <ul className="faq-listaforma">
                <li className="faq-naslovforme">
                  <p>Postavite nam pitanje</p>
                </li>
                <li className="faq-nazivpolja">
                  <p>Mejl</p>
                  <div className="faq-mejlcontainer">
                    <input
                      type="text"
                      id="textbox"
                      name="textbox"
                      className="faq-textbox1"
                      placeholder="peraperic@gmail.com"
                    ></input>
                  </div>
                </li>
                <li className="faq-nazivpoljap">
                  <p>Pitanje</p>
                  <div className="faq-drugicontainer">
                    <textarea
                      name="pitanje"
                      id="textoblast"
                      cols="35"
                      rows="10"
                      className="faq-textbox2"
                      placeholder="Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed?"
                    ></textarea>
                  </div>
                </li>
                <li className="faq-dugme">
                  <div className="faq-dugmecontainer">
                    <p>POŠALJI</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <img src={DonjaSlika} />
        </div>*/}
        <Odgovor
          open={openModal}
          onClose={() => setOpenModal(false)}
          text={textOdgovor}
        />
      </div>
      <div className="faq_donjaslika">
        <img src={DonjaSlika} />
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default FAQ;
