import logo from "./logo.svg";
import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Agenda from "./Pages/Agenda/Agenda";
import FAQ from "./Pages/FAQ/FAQ";
import Panel from "./Pages/Panel/Panel";
import Prijave from "./Pages/Prijave/Prijave";
import Radionice from "./Pages/Radionice/Radionice";
import Partneri from "./Pages/Partneri/Partneri";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" exact element={<Home />} />
          <Route path="/Agenda" exact element={<Agenda />} />
          <Route path="/FAQ" component={FAQ} />
          <Route path="/Panel" component={Panel} />
          <Route path="/Prijave" component={Prijave} />
          <Route path="/Radionice" component={Radionice} />
          <Route path="/Partneri" component={Partneri} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/Agenda" element={<Agenda />} />
        <Route path="/FAQ" exact element={<FAQ />} />
        <Route path="/Panel" exact element={<Panel />} />
        {/* <Route path="/Prijave" exact element={<Prijave />} /> */}
        <Route path="/Radionice" exact element={<Radionice />} />
        <Route path="/Partneri" exact element={<Partneri />} />
      </Routes>
    </div>
  );
}

export default App;
