import React from "react";
import Cutoutovi from "./Cutoutovi";
import hexagon from "./Slike/hexagon.png";

const MainBody = () => {
  function CyclesFja() {
    window.open("https://cycle.rs/", "_blank");
  }

  return (
    <div className="main-body-container par_id" >
      <center className="main-body-text par_id">PARTNER PANELA</center>
      <img
        onClick={CyclesFja}
        className="hexagon-img par_id"
        src={hexagon}
        alt="hexagon"
      ></img>

      <Cutoutovi />
    </div>
  );
};

export default MainBody;
