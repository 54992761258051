import React from "react";
import "./MeniModal.scss";
import Close from "./img/close.png";
const meniModal = ({ open, onClose }) => {
  if (!open) return null;
  console.log("modal opened");
  return (
    <div className="burgerMenu">
      <div className="burgerMenuContainer">
        <p className="close" onClick={onClose}>
          <img src={Close} alt="Close"></img>
        </p>
        <div className="burgerMenuItem" onClick={onClose}>
          <a href="/">POČETNA</a>
        </div>
        <div className="burgerMenuItem">
          <a href="/#/Radionice">RADIONICE</a>
        </div>
        <div className="burgerMenuItem">
          <a href="/#/Panel">PANEL</a>
        </div>
        <div className="burgerMenuItem">
          <a href="/#/Agenda">AGENDA</a>
        </div>
        <div className="burgerMenuItem">
          <a href="/#/FAQ">FAQ</a>
        </div>
        <div className="burgerMenuItem">
          <a href="/#/Partneri">PARTNERI</a>
        </div>
        {/* <div className="burgerMenuItem_prijava">
          <a href="/#/Prijave">PRIJAVI SE</a>
        </div> */}
      </div>
    </div>
  );
};

export default meniModal;
