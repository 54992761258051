import React, { useState, useEffect } from "react";
import "./agenda.scss";
import Meni from "../../Components/Meni";
import Footer from "../../Components/Footer";

import top from "./slike/agenda-DESKTOP-GORE.png";
import top_mobile from "./slike/agenda-MOBILE-GORE.png";
import middle from "./slike/agenda-DESKTOP-cela odjednom.png";
import bottom from "./slike/agenda-DESKTOP-DOLE.png";
import { wait } from "@testing-library/user-event/dist/utils";

var pipak = 0;

const Agenda = () => {
  useEffect(() => {
    document.title = "Agenda";
    setTimeout(function () {
      document.getElementById("ag_content").style.opacity = 1;
    }, 300);
  }, []);

  const agenda1 = [
    {
      time: "9.55–10.15",
      title: "Check in",
      description: "",
    },
    {
      time: "10.15–10.45",
      title: "Otvaranje projekta",
      description: "",
    },
    {
      time: "10.45–12.30",
      title: "Panel",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "12.30–12.50",
      title: "Pauza",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "12.50–14.15",
      title: "I deo radionice",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "14.15–15.00",
      title: "Pauza",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "15.00–16.15",
      title: "II deo radionice",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "16.15–16.35",
      title: "Pauza",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "16.35–17.50",
      title: "III deo radionice",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
  ];

  const agenda2 = [
    {
      time: "11.00–12.15",
      title: "I deo radionice",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "12.15–12.35",
      title: "Pauza",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "12.35–13.50",
      title: "II deo radionice",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "13.50–14.35",
      title: "Pauza",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "14.35–15.50",
      title: "III deo radionice",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "15.50–16.20",
      title: "Pauza",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
    {
      time: "16.20–17.50",
      title: "Zatvaranje",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit.Asperiores possimus quos aliquam dolore at sunt quibusdamperferendis obcaecati iusto dolor. Dignissimos nam illo ipsamrerum recusandae id dolor, esse hic? Ducimus magni eiusperspiciatis ipsam dolores enim sunt accusantium, eum explicabo etinventore cum nostrum delectus! Illum ab labore dolore veritatis,totam eos quidem quaerat blanditiis quas saepe quis odit.",
    },
  ];

  const [agenda, setAgenda] = useState(agenda1);

  function handleClick1() {
    setAgenda(agenda1);
    document.getElementById("ag-one").classList.add("clicked");
    var shapes = document.querySelectorAll(".ag-shape");
    shapes.forEach((shape) => shape.classList.remove("animation"));
    setTimeout(() => {
      shapes.forEach((shape) => shape.classList.add("animation"));
    }, 500);
    document.getElementById("ag-two").classList.remove("clicked");
  }
  function handleClick2() {
    setAgenda(agenda2);
    var shapes = document.querySelectorAll(".ag-shape");
    shapes.forEach((shape) => shape.classList.remove("animation"));
    setTimeout(() => {
      shapes.forEach((shape) => shape.classList.add("animation"));
    }, 500);
    document.getElementById("ag-two").classList.add("clicked");
    document.getElementById("ag-one").classList.remove("clicked");
  }
  return (
    <div>
      <Meni />
      <div className="agenda">
        <div className="ag-back"></div>
        <img src={top} className="ag-back-top" />
        <img src={top_mobile} className="ag-back-top mob" />
        <img src={middle} className="ag-back-hex" />
        <img src={bottom} className="ag-back-bottom" />

        <div className="ag-header">
          <h1>AGENDA</h1>
          <div className="ag-buttons">
            <button
              onClick={handleClick1}
              id="ag-one"
              className="ag-button clicked"
            >
              14.10.2023.
            </button>
            <button onClick={handleClick2} id="ag-two" className="ag-button">
              15.10.2023.
            </button>
          </div>
        </div>
        <div className="ag-container" id="ag_content">
          <div className="ag-line"></div>
          {agenda.map((card) => (
            <div className="ag-card" key={pipak++}>
              <div className="ag-shape animation">
                <h2 className="ag-card-date">{card.time}</h2>
                <h2 className="ag-card-header">{card.title}</h2>
              </div>
              <span className="ag-hex">&#x2B22;</span>
            </div>
          ))}
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Agenda;
