import React, { useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./Odgovor.scss";
const Odgovor = ({ open, onClose, text }) => {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          open = false;
          onClose();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  //if (!open) return null;
  function mobileWidth() {
    if (window.innerWidth < 600) {
      return true;
    }
    return false;
  }
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className="rd_radionicaModal"
          transition={{ ease: "easeIn", duration: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div
            className="rd_radionicaModalcontainer"
            id="pr_odgovor_id"
            ref={wrapperRef}
          >
            <div className="pr_obavestenje_container" onClick={onClose}>
              <div className="pr_obavestenje">
                <p>{text}</p>
              </div>
              <div className="pr_obavestenje">
                <p>OK</p>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Odgovor;
