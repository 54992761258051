import React, { useRef, useEffect } from "react";
import "./radionicaModal.scss";
import Heksagon from "./img/heksagon.png";
import { AnimatePresence, motion } from "framer-motion";
import milan1 from "./img/milan1.png";
import ljubica1 from "./img/ljubica1.png";

var kurac = 0;

const RadionicaModal = ({ open, onClose, text }) => {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          open = false;
          onClose();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  //if (!open) return null;
  function mobileWidth() {
    if (window.innerWidth < 760) {
      return true;
    }
    return false;
  }
  if (mobileWidth()) {
    return (
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ ease: "linear", duration: 0.7 }}
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            className="rd_radionicaModalMobile"
          >
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              transition={{ ease: "easeIn", duration: 1 }}
              className="rd_opis"
            >
              {text.opis}
            </motion.p>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              transition={{ ease: "easeIn", duration: 1 }}
              className="rd_horLine"
            ></motion.div>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              transition={{ ease: "easeIn", duration: 1 }}
            >
              <b>Nivo:</b> {text.nivo}
            </motion.p>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              transition={{ ease: "easeIn", duration: 1 }}
            >
              <b>Potreban laptop:</b> {text.laptop}
            </motion.p>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              transition={{ ease: "easeIn", duration: 1 }}
            >
              <b className="rd_predznanje">Potrebno predznanje :</b>
            </motion.p>
            {text.predznanje.map((elem) => {
              return (
                <motion.li key={kurac++} className="rd_li">
                  {" "}
                  {elem}
                </motion.li>
              );
            })}
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              transition={{ ease: "easeIn", duration: 1 }}
            >
              <b>Potreban softver:</b>
            </motion.p>
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              transition={{ ease: "easeIn", duration: 1 }}
            >
              {text.softveri.map((softver) => {
                if (softver.link === null)
                  return (
                    <motion.li key={softver.id} className="rd_li">
                      {" "}
                      {softver.ime}
                    </motion.li>
                  );
                else
                  return (
                    <a href={softver.link}>
                      <motion.li key={softver.id} className="rd_li">
                        {" "}
                        {softver.ime}
                      </motion.li>
                    </a>
                  );
              })}
            </motion.ul>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              transition={{ ease: "easeIn", duration: 1 }}
              className="rd_horLine"
            ></motion.div>
            {text.predavaci.map((predavac) => {
              return (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, transition: { duration: 0 } }}
                  transition={{ ease: "easeIn", duration: 1 }}
                  className="rd_predavacMobile"
                  key={predavac.id}
                >
                  <motion.h3>Predavač: {predavac.ime}</motion.h3>
                  <motion.img
                    src={predavac.slika}
                    alt=""
                    className="rd_hexMobile"
                  ></motion.img>
                  <motion.p>{predavac.opis}</motion.p>
                </motion.div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    );
  }

  if (!mobileWidth()) {
    return (
      <AnimatePresence>
        {open && (
          <motion.div
            className="rd_radionicaModal"
            transition={{ ease: "easeIn", duration: 0.5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="rd_radionicaModalcontainer" ref={wrapperRef}>
              <div className="rd_vertikalniDeo">
                <div className="vertical-content">
                  <h1>{text.nazivRadionice}</h1>
                  <h3>Nivo</h3>
                  <p className="v">{text.nivo}</p>
                  <h3>Potreban laptop</h3>
                  <p className="v">{text.laptop}</p>
                  <h3>
                    Potrebno <br />
                    predznanje
                  </h3>
                  {text.predznanje.map((elem) => {
                    return (
                      <motion.li key={kurac++} className="rd_li">
                        {" "}
                        {elem}
                      </motion.li>
                    );
                  })}
                  <h3>Potreban softver</h3>
                  <ul className="v">
                    {text.softveri.map((softver) => {
                      if (softver.link === null)
                        return (
                          <motion.li key={softver.id} className="rd_li">
                            {" "}
                            {softver.ime}
                          </motion.li>
                        );
                      else
                        return (
                          <a href={softver.link}>
                            <motion.li key={softver.id} className="rd_li">
                              {" "}
                              {softver.ime}
                            </motion.li>
                          </a>
                        );
                    })}
                  </ul>
                </div>
              </div>
              <div className="line"></div>
              <div className="rd_horizontalniDeo">
                <p onClick={onClose} className="closeBtn">
                  X
                </p>
                <p className="rd_p1">{text.opis}</p>
                <hr className="horizontal-line" />
                {text.predavaci.map((predavac) => {
                  console.log(predavac.slika);
                  return (
                    <div className="rd_predavac" key={predavac.id}>
                      <div>
                        <h3>Predavač: {predavac.ime}</h3>
                        <p className="rd_p">{predavac.opis}</p>
                      </div>
                      <img src={predavac.slika} alt="" className="rd_hex"></img>
                    </div>
                  );
                })}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
};

export default RadionicaModal;
