import React, { useState } from "react";
import { useEffect } from "react";
import Meni from "../../Components/Meni";
import Footer from "../../Components/Footer";
import PocetnaGore from "./img/pocetnaGore2.png";
import PocetnaDole from "./img/pocetnaDole2.png";
import GoreFon from "./img/goreFon.png";
import Krug from "./Krug";
import VelikiKrug from "./VelikiKrug";
import SlikaCore from "./img/slikaPorodica.jpg";
import SlikaPr from "./img/porodica2.jpg";
import SlikaIT from "./img/slikaIT2.jpg";
import SlikaDizajn from "./img/dizajnPorodica.jpg";
import malaKnjiga from "./img/malaKnjiga.png";
import Laptop from "./img/laptop.png";
import velikaKnjiga from "./img/velikaKnjiga2.png";
import it from "./img/it.png";
import hr from "./img/hr.png";
import core from "./img/kore.png";
import diz from "./img/diz.png";
import log from "./img/log.png";
import pr from "./img/pr.png";
import cr from "./img/cr.png";
import Koordinator from "./Koordinator";
import lp from "./img/koordinatori/lp.png";
import at from "./img/koordinatori/at.png";
import ak from "./img/koordinatori/ak.png";
import akg from "./img/koordinatori/akg.png";
import ii from "./img/koordinatori/ii.png";
import mt from "./img/koordinatori/mt.png";
import ar from "./img/koordinatori/ar.png";
import "./home.scss";

export const Home = () => {
  const [idSlike, setIdSlike] = useState("core");
  const PocetnaFja = function () {
    document.getElementById("core-id").style.backgroundColor = "#f99d1d";
    document.getElementById("velika-slika-funkcija").src = core;
  };
  let test = 0;
  //kada se ucita DOM poziva fju koja core-u menja boju
  useEffect(() => {
    document.title = "Home";
    window.scrollTo(0, 0);
    setTimeout(function () {
      console.log(document.getElementsByClassName("home_content"));
      document.getElementsByClassName("home_content")[0].style.opacity = "1";
      document.getElementsByClassName("home_content")[1].style.opacity = "1";
      document.getElementsByClassName("home_content")[2].style.opacity = "1";
      document.getElementsByClassName("home_content")[3].style.opacity = "1";
      document.getElementsByClassName("home_content")[4].style.opacity = "1";
    }, 300);
    PocetnaFja();
  }, []);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (window.innerWidth > 1000) {
  //       switch (test) {
  //         case 0:
  //           PromeniSLikuCORE();
  //           break;
  //         case 1:
  //           PromeniSlikuHR();
  //           break;
  //         case 2:
  //           PromeniSLikuIT();
  //           break;
  //         case 3:
  //           PromeniSLikuCR();
  //           break;
  //         case 4:
  //           PromeniSLikuDES();
  //           break;
  //         case 5:
  //           PromeniSLikuLOG();
  //           break;
  //         case 6:
  //           PromeniSLikuPR();
  //           break;
  //       }
  //     } else {
  //       switch (test) {
  //         case 0:
  //           PromeniSLikuCORE();
  //           break;
  //         case 1:
  //           PromeniSLikuPR();
  //           break;
  //         case 2:
  //           PromeniSlikuHR();
  //           break;
  //         case 3:
  //           PromeniSLikuLOG();
  //           break;
  //         case 4:
  //           PromeniSLikuIT();
  //           break;
  //         case 5:
  //           PromeniSLikuDES();
  //           break;
  //         case 6:
  //           PromeniSLikuCR();
  //           break;
  //         case 7:
  //           PromeniSLikuPR();
  //           break;
  //       }
  //     }
  //     test++;
  //     if (test > 6) test = 0;
  //   }, 2000);
  //   return () => clearInterval(intervalId);
  // }, []);

  const PromeniSLikuCORE = function () {
    setIdSlike("core");
    if (window.innerWidth <= 1000) {
      document.getElementsByClassName("krug-cr")[0].style.display = "none";
      document.getElementsByClassName("krug-core")[0].style.transform = "none";
      document.getElementsByClassName("krug-core")[0].style.transform =
        "translate(0%, 170%)";
      document.getElementsByClassName("krug-pr")[0].style.display = "initial";
      document.getElementsByClassName("krug-pr")[0].style.transform = "none";
      document.getElementsByClassName("krug-pr")[0].style.transform =
        "translate(0%, 170%)";
    }
    document.getElementById("velika-slika-funkcija").src = core;

    document.getElementById("core-id").style.backgroundColor = "#f99d1d";
    document.getElementById("core-boja").style.color = "white";

    document.getElementById("pr-id").style.backgroundColor = "white";
    document.getElementById("pr-boja").style.color = "#f99d1d";

    document.getElementById("hr-id").style.backgroundColor = "white";
    document.getElementById("hr-boja").style.color = "#f99d1d";

    document.getElementById("it-id").style.backgroundColor = "white";
    document.getElementById("it-boja").style.color = "#f99d1d";

    document.getElementById("des-id").style.backgroundColor = "white";
    document.getElementById("des-boja").style.color = "#f99d1d";

    document.getElementById("log-id").style.backgroundColor = "white";
    document.getElementById("log-boja").style.color = "#f99d1d";

    document.getElementById("cr-id").style.backgroundColor = "white";
    document.getElementById("cr-boja").style.color = "#f99d1d";
  };

  const PromeniSLikuPR = function () {
    setIdSlike("pr");
    if (window.innerWidth <= 1000) {
      document.getElementsByClassName("krug-core")[0].style.display = "none";
      document.getElementsByClassName("krug-pr")[0].style.transform =
        "translate(-122%, 170%)";
      document.getElementsByClassName("krug-hr")[0].style.display = "initial";
      document.getElementsByClassName("krug-hr")[0].style.transform = "none";
      document.getElementsByClassName("krug-hr")[0].style.transform =
        "translate(122%, 170%)";
    }

    document.getElementById("velika-slika-funkcija").src = pr;

    document.getElementById("pr-id").style.backgroundColor = "#f99d1d";
    document.getElementById("pr-boja").style.color = "white";

    document.getElementById("core-id").style.backgroundColor = "white";
    document.getElementById("core-boja").style.color = "#f99d1d";

    document.getElementById("hr-id").style.backgroundColor = "white";
    document.getElementById("hr-boja").style.color = "#f99d1d";

    document.getElementById("it-id").style.backgroundColor = "white";
    document.getElementById("it-boja").style.color = "#f99d1d";

    document.getElementById("des-id").style.backgroundColor = "white";
    document.getElementById("des-boja").style.color = "#f99d1d";

    document.getElementById("log-id").style.backgroundColor = "white";
    document.getElementById("log-boja").style.color = "#f99d1d";

    document.getElementById("cr-id").style.backgroundColor = "white";
    document.getElementById("cr-boja").style.color = "#f99d1d";
  };

  const PromeniSlikuHR = function () {
    setIdSlike("hr");
    if (window.innerWidth <= 1000) {
      document.getElementsByClassName("krug-pr")[0].style.display = "none";
      document.getElementsByClassName("krug-hr")[0].style.transform = "none";
      document.getElementsByClassName("krug-hr")[0].style.transform =
        "translate(0%, 170%)";
      document.getElementsByClassName("krug-log")[0].style.display = "initial";
      document.getElementsByClassName("krug-log")[0].style.transform = "none";
      document.getElementsByClassName("krug-log")[0].style.transform =
        "translate(0%, 170%)";
    }

    document.getElementById("velika-slika-funkcija").src = hr;

    document.getElementById("hr-id").style.backgroundColor = "#f99d1d";
    document.getElementById("hr-boja").style.color = "white";

    document.getElementById("pr-id").style.backgroundColor = "white";
    document.getElementById("pr-boja").style.color = "#f99d1d";

    document.getElementById("core-id").style.backgroundColor = "white";
    document.getElementById("core-boja").style.color = "#f99d1d";

    document.getElementById("it-id").style.backgroundColor = "white";
    document.getElementById("it-boja").style.color = "#f99d1d";

    document.getElementById("des-id").style.backgroundColor = "white";
    document.getElementById("des-boja").style.color = "#f99d1d";

    document.getElementById("log-id").style.backgroundColor = "white";
    document.getElementById("log-boja").style.color = "#f99d1d";

    document.getElementById("cr-id").style.backgroundColor = "white";
    document.getElementById("cr-boja").style.color = "#f99d1d";
  };

  const PromeniSLikuLOG = function () {
    setIdSlike("log");
    if (window.innerWidth <= 1000) {
      document.getElementsByClassName("krug-hr")[0].style.display = "none";
      document.getElementsByClassName("krug-log")[0].style.transform = "none";
      document.getElementsByClassName("krug-log")[0].style.transform =
        "translate(-120%, 170%)";

      document.getElementsByClassName("krug-it")[0].style.display = "initial";
      document.getElementsByClassName("krug-it")[0].style.transform = "none";
      document.getElementsByClassName("krug-it")[0].style.transform =
        "translate(122%, 85%)";
    }

    document.getElementById("velika-slika-funkcija").src = log;

    document.getElementById("log-id").style.backgroundColor = "#f99d1d";
    document.getElementById("log-boja").style.color = "white";

    document.getElementById("pr-id").style.backgroundColor = "white";
    document.getElementById("pr-boja").style.color = "#f99d1d";

    document.getElementById("core-id").style.backgroundColor = "white";
    document.getElementById("core-boja").style.color = "#f99d1d";

    document.getElementById("it-id").style.backgroundColor = "white";
    document.getElementById("it-boja").style.color = "#f99d1d";

    document.getElementById("des-id").style.backgroundColor = "white";
    document.getElementById("des-boja").style.color = "#f99d1d";

    document.getElementById("hr-id").style.backgroundColor = "white";
    document.getElementById("hr-boja").style.color = "#f99d1d";

    document.getElementById("cr-id").style.backgroundColor = "white";
    document.getElementById("cr-boja").style.color = "#f99d1d";
  };

  const PromeniSLikuIT = function () {
    setIdSlike("it");
    if (window.innerWidth <= 1000) {
      document.getElementsByClassName("krug-log")[0].style.display = "none";
      document.getElementsByClassName("krug-it")[0].style.transform = "none";
      document.getElementsByClassName("krug-it")[0].style.transform =
        "translate(0%, 85%)";
      document.getElementsByClassName("krug-des")[0].style.display = "initial";
      document.getElementsByClassName("krug-des")[0].style.transform = "none";
      document.getElementsByClassName("krug-des")[0].style.transform =
        "translate(0%, 85%)";
    }

    document.getElementById("velika-slika-funkcija").src = it;

    document.getElementById("it-id").style.backgroundColor = "#f99d1d";
    document.getElementById("it-boja").style.color = "white";

    document.getElementById("pr-id").style.backgroundColor = "white";
    document.getElementById("pr-boja").style.color = "#f99d1d";

    document.getElementById("core-id").style.backgroundColor = "white";
    document.getElementById("core-boja").style.color = "#f99d1d";

    document.getElementById("hr-id").style.backgroundColor = "white";
    document.getElementById("hr-boja").style.color = "#f99d1d";

    document.getElementById("des-id").style.backgroundColor = "white";
    document.getElementById("des-boja").style.color = "#f99d1d";

    document.getElementById("log-id").style.backgroundColor = "white";
    document.getElementById("log-boja").style.color = "#f99d1d";

    document.getElementById("cr-id").style.backgroundColor = "white";
    document.getElementById("cr-boja").style.color = "#f99d1d";
  };

  const PromeniSLikuDES = function () {
    setIdSlike("diz");
    if (window.innerWidth <= 1000) {
      document.getElementsByClassName("krug-it")[0].style.display = "none";
      document.getElementsByClassName("krug-des")[0].style.transform = "none";
      document.getElementsByClassName("krug-des")[0].style.transform =
        "translate(-120%, 85%)";
      document.getElementsByClassName("krug-cr")[0].style.display = "initial";
      document.getElementsByClassName("krug-cr")[0].style.transform = "none";
      document.getElementsByClassName("krug-cr")[0].style.transform =
        "translate(120%, 0%)";
    }

    document.getElementById("velika-slika-funkcija").src = diz;

    document.getElementById("des-id").style.backgroundColor = "#f99d1d";
    document.getElementById("des-boja").style.color = "white";

    document.getElementById("pr-id").style.backgroundColor = "white";
    document.getElementById("pr-boja").style.color = "#f99d1d";

    document.getElementById("core-id").style.backgroundColor = "white";
    document.getElementById("core-boja").style.color = "#f99d1d";

    document.getElementById("it-id").style.backgroundColor = "white";
    document.getElementById("it-boja").style.color = "#f99d1d";

    document.getElementById("hr-id").style.backgroundColor = "white";
    document.getElementById("hr-boja").style.color = "#f99d1d";

    document.getElementById("log-id").style.backgroundColor = "white";
    document.getElementById("log-boja").style.color = "#f99d1d";

    document.getElementById("cr-id").style.backgroundColor = "white";
    document.getElementById("cr-boja").style.color = "#f99d1d";
  };

  const PromeniSLikuCR = function () {
    setIdSlike("cr");
    if (window.innerWidth <= 1000) {
      document.getElementsByClassName("krug-des")[0].style.display = "none";
      document.getElementsByClassName("krug-cr")[0].style.transform = "none";
      document.getElementsByClassName("krug-cr")[0].style.transform =
        "translate(0%, 0%)";
      document.getElementsByClassName("krug-core")[0].style.display = "initial";
      document.getElementsByClassName("krug-core")[0].style.transform = "none";
      document.getElementsByClassName("krug-core")[0].style.transform =
        "translate(120%, 170%)";
    }

    document.getElementById("velika-slika-funkcija").src = cr;

    document.getElementById("cr-id").style.backgroundColor = "#f99d1d";
    document.getElementById("cr-boja").style.color = "white";

    document.getElementById("pr-id").style.backgroundColor = "white";
    document.getElementById("pr-boja").style.color = "#f99d1d";

    document.getElementById("core-id").style.backgroundColor = "white";
    document.getElementById("core-boja").style.color = "#f99d1d";

    document.getElementById("it-id").style.backgroundColor = "white";
    document.getElementById("it-boja").style.color = "#f99d1d";

    document.getElementById("des-id").style.backgroundColor = "white";
    document.getElementById("des-boja").style.color = "#f99d1d";

    document.getElementById("log-id").style.backgroundColor = "white";
    document.getElementById("log-boja").style.color = "#f99d1d";

    document.getElementById("hr-id").style.backgroundColor = "white";
    document.getElementById("hr-boja").style.color = "#f99d1d";
  };

  return (
    <div className="homecontainer">
      <Meni />
      <div id="home_content"></div>
      <div className="hm_gore home_content">
        <div className="hm_title">
          <h1>
            <span id="hm_1">WHERE</span> <span id="hm_2">LEARNING</span>
            <span id="hm_3"> BEGINS!</span>
          </h1>
          <p>14. I 15. OKTOBAR</p>
          <div className="hm_prijavise">PRIJAVE SU ZATVORENE</div>

          <img src={GoreFon} alt="" className="hm_GoreFon" />
          <img src={PocetnaGore} alt="" className="hm_GoreKomp" />
        </div>
      </div>
      <div className="hm_hero home_content">
        <div className="mala-knjiga">
          <img src={malaKnjiga} alt="knjiga" className="hm_knjiga"></img>
          {/* <img src={Laptop} alt="knjiga" className="hm_laptop"></img> */}
        </div>

        <div className="hm_heroText">
          <h1 className="hm_h1Gornji">O PROJEKTU</h1>
          <p className="prvi-p">
            Studenti studentima je projekat Udruženja studenata informatike
            FONIS koji se tradicionalno održava godinama unazad. Na ovom
            projektu možete steći nova znanja, kako iz IT-ja, tako i iz
            menadžmenta i dizajna. Projekat će se održati 14. i 15. oktobra na
            Fakultetu organizacionih nauka, a sastoji se iz 2 dela: panel
            diskusije i devet besplatnih radionica. Vaši predavači će biti
            upravo vaše kolege – studenti koji spremno čekaju da podele svoje
            znanje, što omogućava prijatnu komunikaciju i atmosferu u kojoj se
            lako i brzo uči. Projekat će vam pomoći da proširite svoja
            interesovanja i radite na ličnom napretku, a učešće je besplatno!
            Dobrodošli su svi studenti iz Srbije, bez obzira na Univerzitet ili
            usmerenje. Ako želite da steknete nove veštine, ali i lepe uspomene
            - ovo je idealna prilika za vas!
          </p>
          <div className="velika-knjiga">
            <img src={velikaKnjiga} alt="knjiga2"></img>
          </div>
          <h1>O FONISU</h1>
          <p>
            Udruženje studenata informatike FONIS već pune dve decenije
            predstavlja nezaobilaznu stanicu za sve studente Fakulteta
            organizacionih nauka koji svoju budućnost vide u IT sektoru. Sa
            preko 100 aktivnih, kao i velikom mrežom alumnih članova, FONIS se
            izdvaja kao jedina organizacija na fakultetu usmerena na
            informacione tehnologije. Kroz raznovrsne radionice, seminare,
            predavanja i hakatone, naši članovi razvijaju svoje veštine i grade
            mreže kontakata, doprinoseći ličnom i profesionalnom razvoju. Svakim
            novim projektom FONIS se trudi da neprestano unapređuje znanje iz
            oblasti informacionih tehnologija i postavlja visoke standarde, što
            je prepoznato od strane velikog broja kompanija koje nas već 20
            godina na ovom putu podržavaju.
          </p>
        </div>
      </div>
      <div className="hm_donjaSlika home_content">
        <img src={PocetnaDole} alt="slika" />
      </div>

      <div className="krug-container home_content">
        {/* <div className="hm_kordinatorTekst">
          <p>{tesktSlike}</p>
        </div> */}
        {/* <div className="hm_kordinatorTekst">
          <Krug
            //promeni={PromeniSLikuCORE}
            tekst={tesktSlike}
            imeId="teskst"
          />
        </div> */}
        <div className="krug-core">
          <Krug
            promeni={PromeniSLikuCORE}
            tekst="Tim koordinatora"
            imeId="core-id"
            bojaId="core-boja"
          />
        </div>

        <div className="krug-pr">
          <Krug
            promeni={PromeniSLikuPR}
            tekst="Tim za odnose s javnošću"
            imeId="pr-id"
            bojaId="pr-boja"
          />
        </div>
        <div className="veliki-krug">
          <VelikiKrug tekst={idSlike} idSlike={idSlike}></VelikiKrug>
        </div>

        <div className="krug-hr">
          <Krug
            promeni={PromeniSlikuHR}
            tekst="Tim za ljudske resurse"
            imeId="hr-id"
            bojaId="hr-boja"
          />
        </div>
        <div className="krug-log">
          <Krug
            promeni={PromeniSLikuLOG}
            tekst="Tim za logistiku"
            imeId="log-id"
            bojaId="log-boja"
          />
        </div>
        <div className="krug-it">
          <Krug
            promeni={PromeniSLikuIT}
            tekst="Tim za informacione tehnologije"
            imeId="it-id"
            bojaId="it-boja"
          />
        </div>
        <div className="krug-des">
          <Krug
            promeni={PromeniSLikuDES}
            tekst="Tim za dizajn"
            imeId="des-id"
            bojaId="des-boja"
          />
        </div>
        <div className="krug-cr">
          <Krug
            promeni={PromeniSLikuCR}
            tekst="Tim za odnose sa kompanijama"
            imeId="cr-id"
            bojaId="cr-boja"
          />
        </div>
      </div>
      <Koordinator
        slika={ar}
        hack={"hack"}
        id="ar"
        ime="Anđela Rogljić"
        tekst="Koordinator projekta"
      />
      <div className="Koordinatori home_content">
        <Koordinator
          slika={at}
          ime="Anja Tomić"
          tekst="Koordinator tima za ljudske resurse"
        />
        <Koordinator
          slika={lp}
          id="lp"
          ime="Luka Petrović"
          tekst="Koordinator tima za informacione tehnologije"
        />
        <Koordinator
          slika={ii}
          id="ii"
          ime="Ivana Ilić"
          tekst="Koordinator tima za odnose sa kompanijama"
        />
        <Koordinator
          slika={ak}
          id="ak"
          ime="Anđela Kanjo"
          tekst="Koordinator tima za dizajn"
        />

        <Koordinator
          slika={mt}
          id="mt"
          ime="Milica Todorović"
          tekst="Koordinator tima za logistiku"
        />
        <Koordinator
          slika={akg}
          id="akg"
          ime="Anđela Kragić"
          tekst="Koordinator tima za odnose s javnošću"
        />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
