import "./home.scss";

const Krug = (props) => {
  return (
    <div className="krug-tamni">
      <div className="krug-svetli" onClick={props.promeni}>
        <div id={props.imeId} className="krug-beli">
          <center className="krug-tekst" id={props.bojaId}>
            {props.tekst}
          </center>
        </div>
      </div>
    </div>
  );
};

export default Krug;
