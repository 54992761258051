import React from "react";
import { useState } from "react";
import "./radionicaElement.scss";
import Strelica from "./img/strelica.png";
import RadionicaModal from "./RadionicaModal";
import { motion } from "framer-motion";


const RadionicaElement = ({ slika, tekst, modalText }) => {
  const [openModal, setOpenModal] = useState(false);
  
  const ClickHander = function () {
    document.getElementsByClassName("rd_button")[modalText.id - 1].classList.toggle("clicked");
    setOpenModal(!openModal);
  };
 
  return (
    <div className="RadionicaElementcontainer">
      <img src={slika} alt="" className="rd_img_hex" />
      <div className="rd_button"  onClick={ClickHander}>
        <p>{tekst}</p>
        <img src={Strelica} alt=""></img>
      </div>
      <RadionicaModal open={openModal} 
        onClose={() => setOpenModal(false) } text={modalText}
        />

    </div>
  );


 
};

export default RadionicaElement;
