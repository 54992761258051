import React from "react";
import { useState } from "react";
import FAQOdgovor from "./FAQOdgovor";
import "./FAQPitanje.scss";
import munjica1 from "./slike/Vector 11.png";
import munjica2 from "./slike/Vector 8.png";
import { motion } from "framer-motion";
const FAQPitanje = ({ tekst, odgovor }) => {
  const [openModal, setOpenModal] = useState(false);
  const ClickHander = function () {
    setOpenModal(!openModal);
  };
  return (
    <div onClick={ClickHander} className="faq_pitanje">
      <div className="faq_pitanjetekst">
        <p>{tekst} </p>
        <motion.img 
        animate={{rotate: openModal? 90 : 0}}
        transition={{ease:"linear" ,duration: 0.7}}
        src={munjica1} alt="" className="faq-munja" />
        {/* <img src={munjica2} alt="" className="faq-munja2" /> */}
      </div>
      <div className="faq_pitanjeodgovor">
        <FAQOdgovor
          open={openModal}
          onClose={() => setOpenModal(false)}
          odgovor={odgovor}
        />
      </div>
    </div>
  );
};

export default FAQPitanje;
