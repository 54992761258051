import React from "react";
import "./koordinator.scss";
const Koordinator = ({ slika, ime, tekst, id, hack }) => {
  return (
    <div className="Koordinator" id={hack}>
      <img src={slika} id={id} />
      <p>
        {ime}
        <br />
        {tekst}
      </p>
    </div>
  );
};

export default Koordinator;
