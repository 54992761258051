import React, { useState, useEffect } from "react";
import "./radionice.scss";
import Meni from "../../Components/Meni";
import Footer from "../../Components/Footer";
import Zavesa from "./img/Zavesa.png";
import RadionicaElement from "./RadionicaElement";
import Heksagon from "./img/heksagon.png";
import HeksagonAe from "./img/heksagonAe.png";
import HeksagonFigma from "./img/heksagonFigma.png";
import HeksagonJava from "./img/heksagonJava.png";
import HeksagonLoop from "./img/heksagonLoop.png";
import HeksagonNext from "./img/heksagonNext.png";
import HeksagonPython from "./img/heksagonPython.png";
import HeksagonReact from "./img/heksagonReact.png";
import HeksagonShopify from "./img/heksagonShopify.png";
import HeksagonUnity from "./img/heksagonUnity.png";
import Terzic from "./img/terzic2.png";
import Boris from "./img/boris1.png";
import Milan from "./img/milan2.png";
import Ljubica from "./img/ljubica2.png";
import ItPedja from "./img/ItPedja2.png";
import Coda from "./img/coda2.png";
import Vukan from "./img/vukan2.png";
import Krle from "./img/krle2.png";
import Vladimir from "./img/vladimirMilic1.png";
import Oketic from "./img/oketic2.png";
import Mirilo from "./img/mirilo2.png";
import DjPedja from "./img/DjPedja2.png";

import ZavesaDole from "./img/ZavesaDole.png";

const Radionice = () => {
  useEffect(() => {
    document.title = "Radionice";
    setTimeout(function () {
      document.getElementById("rd_id").style.opacity = 1;
    }, 300);
  }, []);

  const content1 = [
    {
      id: 1,
      nazivRadionice: "Python in 21 steps ",
      slika: HeksagonPython,
      nivo: "Osnovni",
      laptop: "Da",
      predznanje: [
        "Osnovni koncepti proceduralnog programiranja",
        "Osnovni koncepti objektno-orijentisanog programiranja",
        "Poželjno poznavanje različitih struktura podataka",
      ],
      softveri: [
        {
          id: 1,
          ime: "PyCharm Community Edition",
          link: "https://www.jetbrains.com/pycharm/download/?section=windows",
        },
        {
          id: 2,
          ime: "Python",
          link: "https://www.python.org/downloads/release/python-3100/",
        },
      ],
      opis: "Jesi li znao/la da je o programskom jeziku Python napisana pesma pod nazivom „The Zen of Python“ koja ističe filozofiju ovog programskog jezika? Ovaj popularan programski jezik kojim se služe velike kompanije poput NASA-e, Google-a, Netflix-a nalazi svoju primenu u mašinskom učenju, web development-u, desktop aplikacijama i mnogim drugim poljima. \nNa ovoj radionici upoznaćeš se sa osnovnim pojmovima u programiranju, kao i sintaksom programskog jezika Python. Potom ćeš uz pomoć predavača napraviti simulaciju igre BlackJack kroz konzolu. Takođe, naučićeš osnove mašinskog učenja i upoznati se sa različitim algoritmima i primeniti ih u kreiranoj igrici.",
      predavaci: [
        {
          id: 1,
          ime: "Milan Pavlović",
          opis: "Milan je student četvrte godine Elektrotehničkog fakulteta u Beogradu, smer Softversko inženjerstvo, član tima „Arvucello“, osvajača prvog Hakatona za srednjoškolce (FONIS) i drugog mesta na narednom HZS-u. Sa Python-om se prvi put sreo upravo na prvom HZS-u, a rad sa njim nastavio kroz fakultet i prakse. Python je do sada koristio za izradu projekta uz Django – napredni web framework, izradu neuralnih mreža (duboko učenje), rad sa API-jem, simulaciju različitih struktura podataka i algoritama kroz projekte na fakultetu... U slobodno vreme mađioničar i kvizoman, fanatik serija „How I met your mother“ i „Game of thrones“, veliki poznavalac DC i Marvel stripova.",
          slika: Milan,
        },
        {
          id: 2,
          ime: "Ljubica Muravljov",
          opis: "Ljubica je studentkinja četvrte godine Softverskog inženjerstva na Elektrotehničkom fakultetu. Sa Milanom se poznaje od početka studija kada započinje njihova saradnja iz koje se rađa sjajan tim. Svoje bogato znanje i iskustvo koje će Ljubica podeliti na radionicama stekla je u kompanijama Levi9 i Microsoft. Sa Python-om i mnogim njegovim mogućnostima se upoznala na fakultetu što je primenila radeći na brojim projektima. Neki od njih su:\nObrada zvuka i slika \nJednostavne igrice\nObavljanje klasifikacije uz pomoć neuralnih mreža \nDjango - napredni web framework \ni mnogi drugi...",
          slika: Ljubica,
        },
      ],
    },
    {
      id: 2,
      nazivRadionice: "React: Flexible frontend",
      slika: HeksagonReact,
      nivo: "Osnovni",
      predznanje: ["HTML", "CSS", "Osnove JavaScript-a"],
      laptop: "Ne",
      softveri: [
        {
          id: 1,
          ime: "Visual Studio Code",
          link: "https://code.visualstudio.com/download",
        },
        {
          id: 2,
          ime: "NodeJS",
          link: "https://nodejs.org/en/download",
        },
      ],
      opis: "Znaš HTML, CSS i JavaScript, ali ti deluje da se od toga ne može napraviti veoma ozbiljna aplikacija? Sa React-om, sigurno nećeš pogrešiti. Ovu tehnologiju odlikuje velika fleksibilnost i pogodna je za aplikacije bilo koje veličine. Ukoliko želiš da se upustiš u svet ubedljivo najpopularnije veb tehnologije, ovo je prava radionica za tebe. Preći ćeš  preko bitnih koncepata kao što su virtuelni DOM, komponente, JSX, state, kao i preko najosnovnijih hook-ova. Sve ovo ćeš proći kroz par prostih primera, ali te zato, na kraju, čeka kreiranje prave aplikacije koju možeš da staviš u svoj portfolio. Ako te je ovo zainteresovalo, pridruži nam se na ovogodišnjoj React radionici.",
      predavaci: [
        {
          id: 1,
          ime: "Predrag Stojković",
          opis: "Predrag je trenutno student treće godine na Fakultetu organizacionih nauka. Već od osnovne škole, pokazivao je izuzetno interesovanje za programiranje, a tokom srednje škole usmerio se ka web development-u. Njegova strast prema informacionim tehnologijama i kodiranju odvela ga je na put uspeha.\nUz više od tri godine iskustva u web development-u, Predrag je stekao impresivne veštine u izradi web stranica i aplikacija. Takođe, angažovanje u studentskoj organizaciji FONIS, već godinu dana, dodatno ga je obogatilo iskustvom i veštinama u oblasti informacionih tehnologija. Trenutno obavlja poziciju koordinatora tima za informacione tehnologije na projektu Hakaton za srednjoškolce 6.0, što mu omogućava da deli svoje znanje i iskustvo sa kolegama unutar organizacije.",
          slika: ItPedja,
        },
      ],
    },
    {
      id: 3,
      nazivRadionice: "Dizajniranje\nbez granica",
      slika: HeksagonFigma,
      nivo: "Osnovni",
      laptop: "Ne",
      predznanje: ["Nije potrebno predznanje"],
      softveri: [{ id: 1, ime: "Nije potreban", link: null }],
      opis: "Figma je poznata po svojoj jednostavnosti i mogućnosti timskog rada u realnom vremenu, čineći je idealnim izborom za buduće dizajnere. Na ovoj radionici naučićeš osnovne veštine korišćenja Figme. Upoznaćeš se sa osnovnim principima dizajna i kreativnim konceptom osmišljavanja dizajnerskog rešenja. Imaćeš priliku da napraviš svoj sajt i u diskusiji razmotriš svoje buduće korake u svetu dizajna. Takođe ćeš se i oprobati u tome da samostalno napraviš neki projekat. Ako želiš da svoju strast prema dizajnu pretvoriš u karijeru, ne propusti priliku da se prijaviš na našu radionicu i napraviš prvi korak ka svom cilju.",
      predavaci: [
        {
          id: 1,
          ime: "Danilo Vasović",
          opis: "Danilo je student treće godine Fakulteta organizacionih nauka. Grafičkim dizajnom se bavi već skoro devet godina i radio je za četiri firme u toj oblasti. Tokom svih tih godina, uvek je tražio program koji najviše može da mu olakša posao. Figma je svakako velika prekretnica u tom smislu. Pre svega, smatra da je bolja od ostalih dizajnerskih programa, baš zbog toga što joj se pristupa preko pretraživača, a sav vaš rad je istog trenutka sačuvan na internetu. Velika prilagodljivost, olakšano korišćenje i povećana pokretljivost su svakako epiteti koje bi dodelio Figmi. Smatra da poznavanje ovog programa može da pomogne i olakša ljudima u oblasti dizajna, bilo da oni prave izgled zida za Instagram ili izgled internet stranice.",
          slika: Coda,
        },
      ],
    },
    {
      id: 4,
      nazivRadionice: "Where AGILE \nmeets students",
      slika: HeksagonLoop,
      nivo: "Osnovni",
      laptop: "Ne",
      predznanje: ["Nije potrebno predznanje"],
      softveri: [{ id: 1, ime: "Nije potreban", link: null }],
      opis: "Kad se kaže agilni menadžment ili agilna kompanija, prva pomisao je da je u pitanju brzo, okretno i prilagodljivo poslovanje. Nešto što dobri preduzetnici ili direktori imaju u krvi, malo talenat, a malo iskustvo i razum. Nakon samo par minuta pretraživanja postaje jasno da je u pitanju čvrsta formula koju uspešno primenjuju najpoznatije softverske firme, poput Majkrosofta.\nAgilni proces omogućava da se proizvod brzo lansira na tržište u svojoj osnovnoj verziji. Zatim, kroz redovne iteracije i povratne informacije korisnika, postepeno se unapređuje dok ne dostigne svoju konačnu verziju, koja uspešno zadovoljava potrebe tržišta sa svim zamišljenim funkcionalnostima. Ovaj ciklični proces omogućava brži razvoj proizvoda, bolju prilagodljivost tržištu i veću mogućnost iteracije od tradicionalnih metoda, pa je zato sve popularniji među projektnim menadžerima.\nOva radionica biće mesto za objedinjavanje svih principa agilnog upravljanja projektima, kao i za postavljanje dobrih temelja Scrum okvira. Razjasnićemo šta su artefakti, ceremonije i ko čini jedan Scrum tim, sve na bazi praktičnih primera, igara i što manje teorije.",
      predavaci: [
        {
          id: 1,
          ime: "Boris Vajagić",
          opis: "Boris je apsolvent na Fakultetu organizacionih nauka i menadžer platforme FONBoard. Tokom svog studiranja fokus je usmerio na razvoj u oblasti menadžmenta u IT-ju kroz obavljanje različitih uloga - predsednika i člana Upravnog odbora za upravljanje projektima u Udruženju studenata informatike FONIS, demonstratora na Katedri za elektronsko poslovanje i praktikanta na poziciji Product Management Intern u kompaniji Seven Bridges. Smatra da se mnogi aspekti našeg života mogu posmatrati kao projekti kojima se može pristupiti agilno.",
          slika: Boris,
        },
        {
          id: 2,
          ime: "Bogdan Terzić",
          opis: "Bogdan je student treće godine Fakulteta organizacionih nauka i praktikant u kompaniji Lukowa Food na poziciji Generative AI Intern. Član je Udruženja studenata informatike FONIS u okviru kog se do sada oprobao u timovima za korporativne odnose i ljudske resurse, dok je u drugoj studentskoj organizaciji bio tim lider za logistiku. Vidi sebe kao fleksibilnu osobu otvorenog uma koja je spremna da prihvati nove izazove. Nastoji da proširi i podeli svoja znanja iz oblasti upravljanja projektima, upravljanja događajima i preduzetništva. Ljudi ga opisuju kao ambicioznog i vrednog timskog igrača, jer stavlja najbolje interese svog tima na prvo mesto.",
          slika: Terzic,
        },
      ],
    },
    {
      id: 5,
      nazivRadionice: "Tvoj put do \nprve Unity igre",
      slika: HeksagonUnity,
      nivo: "Osnovni",
      laptop: "Da",
      predznanje: [
        "Poznavanje osnovnih koncepata objektno-orijentisanog programiranja",
      ],
      softveri: [
        {
          id: 1,
          ime: "Unity razvojno okruženje",
          link: "https://unity.com/download",
        },
        {
          id: 2,
          ime: "Visual Studio",
          link: "https://visualstudio.microsoft.com/downloads/",
        },
        {
          id: 3,
          ime: "Uputstvo",
          link: "https://youtu.be/4ElwPXo4oCc?si=Sdp3tvKiVR6DBUe1",
        },
      ],
      opis: "Da li si znao/la da su igrice poput Five Nights at Freddy’s, Pokemon GO i The Forest nastale u Unity-u, jednoj od dve najjače i najpopularnije platforme za razvoj video igara? \nNa radionici ćeš se upoznati sa Unity razvojnim okruženjem, naučićeš da dodaješ objekte i određene komponente. Takođe, saznaćeš kako da kreiraš igrača i njegovog protivnika i omogućiš interakciju među njima. Radićeš na korisničkom interfejsu i naposletku dodavati zvučne efekte i raznovrsne animacije.\nPridruži se i usvoji osnove ove moćne alatke u samo dva dana. Napravi svoju prvu video igru bilo da si u svetu gejminga ili ne!",
      predavaci: [
        {
          id: 1,
          ime: "Vukan Radošević",
          opis: "Vukan je student prve godine Fakulteta organizacionih nauka, na smeru Informacioni sistemi i tehnologije. Oduvek ga je privlačio svet video igara, zbog čega je u drugoj godini srednje škole odlučio da počne sa radom u Unity-ju. Do sada je učestvovao na tri GameJam-a i na Hakatonu, na kome je takođe radio na izradi video igrice. Ljubav i interesovanje prema video igricama motivisali su ga da se usavršava upravo u toj oblasti. Tema maturskog rada u srednjoj školi mu je bila „Razvoj klasa i rešavanje određenog problema u video igrici“, čime je napravio prvi korak u razvoju svoje potencijalne karijere. Neke od igara na kojima je radio možeš pronaći na https://blackjuice.itch.io. ",
          slika: Vukan,
        },
      ],
    },
    {
      id: 6,
      nazivRadionice: "Kako pokrenuti \ne-commerce biznis",
      slika: HeksagonShopify,
      nivo: "Osnovni",
      laptop: "Ne",
      predznanje: ["Nije potrebno predznanje"],
      softveri: [{ id: 1, ime: "Nije potreban", link: null }],
      opis: "Da li znaš da je online kupovina u stalnom porastu, kako u Srbiji, tako i u svetu? Svakodnevno raste i broj online prodavnica na tržištu. Baš zato smo za tebe pripremili radionicu na kojoj ćeš moći da naučiš kako da pokreneš svoju prvu online prodavnicu, korak po korak. Bavićemo se izborom proizvoda, istraživanjem tržišta, kreiranjem brenda i promovisanjem online prodavnice kroz digitalni marketing preko poznatih društvenih mreža kao što su Instagram, Facebook, TikTok... Takođe ćeš imati priliku da naučiš kako se koristi Shopify platforma, na kojoj ćeš kreirati svoju stranicu proizvoda, a najbolje stranice će biti nagrađene! Naši predavači su tu da ti sve detaljno objasne i podstaknu tvoje želje za kreiranjem uspešnih e-commerce biznisa u budućnosti. Zato nemoj da propustiš priliku da naučiš nešto novo i uz to nešto i osvojiš!",
      predavaci: [
        {
          id: 1,
          ime: "Aleksa Krstić",
          opis: 'Aleksa je student četvrte godine na Fakuletetu organizacionih nauka na smeru Informacioni sistemi i tehnologije. Pored toga što je član Udruženja studenata informatike FONIS, Aleksa je i član studentske organizacije Savez studenata FON-a u kojoj je bio koordinator projekta "Dani prakse". Aktivno se bavi poslovima i aktivnostima vezanim za CR i odlično je upoznat sa poslovanjem. ',
          slika: Krle,
        },
        {
          id: 2,
          ime: "Vladimir Milić",
          opis: "Vladimir je četvrta godina Poslovnog fakulteta na Univerzitetu Singidunum u Beogradu, na smeru Marketing i Menadžment. Tokom poslednje tri godine, Vladimir i Aleksa su se upustili u e-commerce avanturu na srpskom tržištu. Osnivali su nekoliko različitih online prodavnica, kreirali brendove i pomagali drugima da započnu ili unaprede svoje online biznise. Njihova iskustva obuhvataju razne aspekte digitalnog poslovanja i marketinga, sa fokusom na performans marketing i oglašavanje na Facebook-u i Instagram-u.",
          slika: Vladimir,
        },
      ],
    },
    {
      id: 7,
      nazivRadionice: "Java kroz Android Studio",
      slika: HeksagonJava,
      nivo: "Srednji",
      laptop: "Da",
      predznanje: [
        "Poznavanje osnovnih koncepata objektno-orijentisanog programiranja",
      ],
      softveri: [
        {
          id: 1,
          ime: "Java",
          link: "https://www.java.com/en/download/manual.jsp",
        },
        {
          id: 2,
          ime: "Android Studio",
          link: "https://developer.android.com/studio?gclid=CjwKCAjwr_CnBhA0EiwAci5sikT_jXuZV6kxuqKRkTZ5-hqkTlFo440jkf-uE2aasedRoz1VAy-3qRoCZYwQAvD_BwE&gclsrc=aw.ds ",
        },
        {
          id: 3,
          ime: "Uputstvo",
          link: "https://www.youtube.com/watch?v=DM783YA0vbc",
        },
      ],
      opis: "Java je bez sumnje jedan od najkorišćenijih jezika u programiranju, prvenstveno iz razloga što ima široku primenu u skoro svim oblastima softverskog inženjerstva: od desktop i web aplikacija, preko veštačke inteligencije, pa sve do mobilnih aplikacija. \nU okviru ove radionice imaćeš priliku da uđeš u svet mobilnih i android tehnologija. Upoznaćeš se sa Android Studiom, savladaćeš osnove Java programiranja za Android, naučićeš kako da kreiraš korisnički interfejs (UI) i proste funkcionalnosti. Takođe, imaćeš priliku da kreiraš svoju prvu Android aplikaciju, da je testiraš na mobilnom telefonu i objaviš na Google Play Store.",
      predavaci: [
        {
          id: 1,
          ime: "Filip Oketić",
          opis: "Filip je student prve godine na Fakultetu organizacionih mauka, gde započinje svoje akademsko putovanje ka postizanju ciljeva u oblasti informacionih tehnologija. \nJoš tokom srednje škole, Filip se isticao svojim izuzetnim veštinama u programiranju i razvoju softvera. Tokom tog perioda, razvio je niz Android aplikacija koje su bile nagrađene na različitim školskim takmičenjima i projektima. \nJedan od Filipovih najznačajnijih uspeha bio je kada je njegov tim osvojio prvo mesto na MTS App Konkursu. Ovaj uspeh omogućio im je da predstavljaju Srbiju na evropskom takmičenju u Estoniji. To iskustvo doprinelo je Filipovom ličnom i profesionalnom razvoju i postavilo temelje za želju u razvoju informacionih tehnologija.",
          slika: Oketic,
        },
      ],
    },
    {
      id: 8,
      nazivRadionice: "Next.js in a Nutshell",
      slika: HeksagonNext,
      nivo: "Napredni",
      laptop: "Ne",
      predznanje: [
        "HTML, CSS, JS - srednji nivo",
        "Git - osnovni nivo",
        "Poznavanje osnovnih koncepata backend-a",
      ],
      softveri: [
        {
          id: 1,
          ime: "Visual Studio Code",
          link: "https://code.visualstudio.com/download",
        },
        {
          id: 2,
          ime: "NodeJS",
          link: "https://nodejs.org/en/download",
        },
        { id: 3, ime: "Git", link: "https://git-scm.com/downloads" },
      ],
      opis: "Ako se baviš razvojem web aplikacija,  sigurno si čuo za Next.js. U pitanju je React framework za razvoj web aplikacija koji poseduje nekoliko dodatnih funkcionalnosti koje ga čine superiornim alatom. Na radionici ćemo kroz dva dana pokriti sve što je potrebno da znaš da bi mogao da napraviš jednu full-stack web aplikaciju. Iako znamo da teorija sama po sebi nije mnogo zanimljiva, ona ipak predstavlja temelj svakog kvalitetnog učenja, pa će tako biti i ovaj put. Ipak, kruna radionice je svakako razvoj same full-stack web aplikacije primenom znanja koje smo prethodno naučili. Obradićemo tehnologije kao što su Next.js, Tailwind CSS, TypeScript, MongoDB i Git.",
      predavaci: [
        {
          id: 1,
          ime: "Nikola Mirilo",
          opis: 'Nikola je diplomirani inženjer organizacionih nauka na smeru Operacioni menadžment Fakulteta organizacionih nauka. Zaposlen je u kompaniji Adacta kao Junior IT projektni menadžer u DACH regiji. Sa strane radi kao freelanceer full stack Next.js web developer. Programiranjem se bavi već četiri godine od čega dve i po godine koristi tehnologje React.js i Next.js. Bio je predavač na prethodne dve iteracije S2S-a na temu "React.js from Zero to Hero" i "React at its finest". Pored navedenog bavi se blockchain-om, Search Engine Optimization-om, automatizacijom korišćenjem Python-a i Excel-a i drži časove iz web programiranja, Python-a i Excel-a.',
          slika: Mirilo,
        },
      ],
    },
    {
      id: 9,
      nazivRadionice: "Vodič kroz After Effects",
      slika: HeksagonAe,
      nivo: "Osnovni",
      laptop: "Da",
      predznanje: ["Nije potrebno predznanje"],
      softveri: [
        {
          id: 1,
          ime: "Adobe After Effects",
          link: "https://www.adobe.com/products/aftereffects/free-trial-download.html",
        },
      ],
      opis: "Bez obzira da li si početnik ili iskusni profesionalac, Adobe After Effects nudi beskrajne mogućnosti za eksperimentisanje i izražavanje kreativnosti. Na ovoj radionici ćeš se upoznati sa interfejsom i osnovama After Effects-a, konceptima animacije i keyframe-ova. Tokom radionice ćeš izrađivati: 2D animirane reklame, animirane Instagram Story-je i Reels-eve kao i animacije logoa.",
      predavaci: [
        {
          id: 1,
          ime: "Predrag Tanasković",
          opis: "Predrag je student četvrte godine na Fakultetu organizacionih nauka, na smeru Informacioni sistemi i tehnologije. Sa čak sedam godina iskustva u Adobe-ovom setu programa, posebno u svom omiljenom Adobe After Effects-u, video editing i dizajn su postali njegova strast. Van akademskih obaveza, zaljubljen je u muziku, folklor i deejay-ing. Na našoj radionici ćemo zajedno, kroz stvaranje animiranih videa, Instagram Story-ja, Reels-eva i animacije logotipa, otkriti kako napraviti zanimljive i atraktivne  sadržaje za društvene mreže.",
          slika: DjPedja,
        },
      ],
    },
  ];

  const [content, setContent] = useState(content1);

  console.log(content);

  return (
    <div className="radionicecontainer">
      <Meni />
      <div className="rd_background"></div>
      <div className="rd_zavesaTitle">
        <p className="rd_Title">RADIONICE</p>
        <img src={Zavesa} alt="" className="rd_zavesa"></img>
      </div>
      <div className="rd_radionice" id="rd_id">
        <div className="rd_radionniceRed">
          {content.map((radionica) => {
            if (radionica.id < 4)
              return (
                <RadionicaElement
                  key={radionica.id}
                  slika={radionica.slika}
                  tekst={radionica.nazivRadionice}
                  modalText={radionica}
                />
              );
            else return null;
          })}
        </div>
        <div className="rd_radionniceRed">
          {content.map((radionica) => {
            if (radionica.id > 3 && radionica.id < 7)
              return (
                <RadionicaElement
                  key={radionica.id}
                  slika={radionica.slika}
                  tekst={radionica.nazivRadionice}
                  modalText={radionica}
                />
              );
            else return null;
          })}
        </div>
        <div className="rd_radionniceRed">
          {content.map((radionica) => {
            if (radionica.id > 6)
              return (
                <RadionicaElement
                  key={radionica.id}
                  slika={radionica.slika}
                  tekst={radionica.nazivRadionice}
                  modalText={radionica}
                />
              );
            else return null;
          })}
        </div>
        <div className="rd_zavesaDole">
          <img src={ZavesaDole} alt="" className="rd_zavesaDoleimg"></img>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Radionice;
