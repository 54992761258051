import React, { useEffect, useState } from "react";
import Meni from "../../Components/Meni";
import "./Panel.scss";
import Footer from "../../Components/Footer";
import Zavesa from "./img/Zavesa.png";
import Sredina from "../../Components/Panel/panel-DESKTOP-SREDINA.png";
import SredinaTelefon from "../../Components/Panel/panel-MOBILE-SREDINA.png";
import Panelista from "./Panelista.jsx";
import OPanelisti from "./OPanelisti.jsx";
import Heksagon from "./img/beliHexagon.png";
import Donja from "../../Components/Panel/panel-DESKTOP-DOLE.png";
import OPanelistiTelefon from "./OPanelistiTelefon.jsx";
import S1 from "./img/s1.png";
import S3 from "./img/s3.png";
import S2 from "./img/s2.png";
import S4 from "./img/s4.png";
import Ognjen from "./img/ognjen.png";
import Boris from "./img/Boris.png";
import Mirilo from "./img/Mirilo.png";
import Tamara from "./img/Tamara.png";
import Tatjana from "./img/TatjanaStojanovic.png";
import Soske from "./img/UrosSosevic.png";
const Panel = () => {
  //let pom = 0;
  let display1 = true;
  let display2 = true;
  let display3 = true;
  let display4 = true;
  let display5 = true;
  const [openModal, setOpenModal] = useState(false);
  const [openModalModerator, setOpenModalModerator] = useState(false);
  const [pom, setPom] = useState(0);
  const [ime, setIme] = useState("");
  const [opis, setOpis] = useState("");
  const [slika, setSlika] = useState("");
  const [kompanija, setKompanija] = useState("");

  useEffect(() => {
    document.title = "Panel";
    setTimeout(function () {
      document.getElementById("pnl_id").style.opacity = 1;
    }, 300);
  }, []);

  const NamestiDisplay = function (indeks, display) {
    if (window.innerWidth < 769) {
      display = false;
      console.log(display);
      document.getElementsByClassName("Panelista")[indeks].style.display =
        "none";
      document.getElementsByClassName("OPanelistiTelefon")[
        indeks
      ].style.display = "flex";
      return;
    }
  };

  const Iskljuci = function (indeks, display) {
    document.getElementsByClassName("Panelista")[indeks].style.display = "flex";
    document.getElementsByClassName("OPanelistiTelefon")[indeks].style.display =
      "none";
    display = true;
  };

  const Moderator = function () {
    if (window.innerWidth < 769) {
      if (display1) {
        document
          .getElementsByClassName("Panelista")[0]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[0]
          .classList.toggle("pnl_show");
        display1 = false;
      } else {
        document
          .getElementsByClassName("Panelista")[0]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[0]
          .classList.toggle("pnl_show");
        display1 = true;
      }
      return;
    }
    console.log(pom);
    if (pom == 1) {
      setPom(0);
      setOpenModalModerator(false);
      return;
    } else {
      console.log(pom);
      // pom = 1;
      setPom(1);
      setOpenModalModerator(true);
      return;
    }
  };

  const Panelista1 = function () {
    setIme("Tatjana Stojanović");
    setOpis(
      "Tatjana Stojanović je asistentkinja na Katedri za softversko inženjerstvo na Fakultetu organizacionih nauka. Pored rada u nastavi, autor je više od 20 naučnih radova i učesnik različitih nacionalnih i međunarodnih projekata. Oblasti interesovanja su joj softverske arhitekture i paterni sa fokusom na .NET tehnologije."
    );
    setSlika(Tatjana);
    if (window.innerWidth < 769) {
      if (display1) {
        document
          .getElementsByClassName("Panelista")[1]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[1]
          .classList.toggle("pnl_show");
        display1 = false;
      } else {
        document
          .getElementsByClassName("Panelista")[1]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[1]
          .classList.toggle("pnl_show");
        display1 = true;
      }
      return;
    }
    if (pom == 1) {
      setPom(0);
      setOpenModal(false);
      return;
    } else {
      setPom(1);
      setOpenModal(true);
      setTimeout(function () {
        window.scrollBy(0, 800);
      }, 2);
      return;
    }
  };
  const Panelista2 = function () {
    if (window.innerWidth < 769) {
      if (display2) {
        document
          .getElementsByClassName("Panelista")[2]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[2]
          .classList.toggle("pnl_show");
        display2 = false;
      } else {
        document
          .getElementsByClassName("Panelista")[2]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[2]
          .classList.toggle("pnl_show");
        display2 = true;
      }
      return;
    }
    if (pom == 2) {
      setPom(0);
      setOpenModal(false);
      return;
    }
    setSlika(Soske);
    setIme("Uroš Šošević");
    setOpis(
      "Uroš Šošević je rođen 1987. godine, diplomirao je na Fakultetu organizacionih nauka gde je odbranio i doktorsku disertaciju pod nazivom “Softverski okvir za razvoj multimodalnih biometrijskih sistema“ u septembru 2022. godine. Uroš je docent na Fakultetu organizacionih nauka, ali i suosnivač i CTO “Unique Venture Clubs” – platforme za veb3 investitore, zajednice i osnivače. Takođe je bio suosnivač i Blockchain arhitekta u “Blinking” – kompaniji za razvoj softvera sa fokusom i stručnošću u izgradnji i razvoju digitalnog identiteta, verifikacije identiteta i zaštite podataka korisnika."
    );
    setPom(2);
    setOpenModal(true);
    setTimeout(function () {
      window.scrollBy(0, 800);
    }, 2);
  };
  const Panelista3 = function () {
    if (window.innerWidth < 769) {
      if (display3) {
        document
          .getElementsByClassName("Panelista")[3]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[3]
          .classList.toggle("pnl_show");
        display3 = false;
      } else {
        document
          .getElementsByClassName("Panelista")[3]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[3]
          .classList.toggle("pnl_show");
        display3 = true;
      }
      return;
    }

    if (pom == 3) {
      setPom(0);
      setOpenModal(false);
      return;
    }
    setSlika(Boris);
    setIme("Boris Vajagić");
    setOpis(
      "Boris je apsolvent na Fakultetu organizacionih nauka i menadžer platforme FONBoard. Tokom svog studiranja fokus je usmerio na razvoj u oblasti menadžmenta u IT-ju kroz obavljanje različitih uloga - predsednika i člana Upravnog odbora za upravljanje projektima u Udruženju studenata informatike FONIS, studenta demonstratora na Katedri za elektronsko poslovanje i praktikanta na poziciji Product Management Intern u kompaniji Seven Bridges. U junu 2023. godine zajedno sa timom predstavljao je Srbiju na Međunarodnom takmičenju u upravljanju projektima održanom u Valensiji, Španija. Smatra da se mnogi aspekti našeg života mogu posmatrati kao projekti kojima se može pristupiti agilno."
    );
    setPom(3);
    setOpenModal(true);
    setTimeout(function () {
      window.scrollBy(0, 800);
    }, 2);
  };
  const Panelista4 = function () {
    if (window.innerWidth < 769) {
      if (display4) {
        document
          .getElementsByClassName("Panelista")[4]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[4]
          .classList.toggle("pnl_show");
        display4 = false;
      } else {
        document
          .getElementsByClassName("Panelista")[4]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[4]
          .classList.toggle("pnl_show");
        display4 = true;
      }
      return;
    }

    if (pom == 4) {
      setPom(0);
      setOpenModal(false);

      return;
    }
    setSlika(Tamara);
    setIme("Tamara Naumović");
    setOpis(
      "Tamara Naumović je asistent na Katedri za elektronsko poslovanje, Fakulteta organizacionih nauka. Svoju karijeru u IT-ju je započela u privredi na poziciji junior ITSM inženjera, da bi nakon upisa master akademskih studija 2017. godine, odlučila da svoju karijeru nastavi na Fakultetu organizacionih nauka. Nauka ju je oduvek interesovala i smatra da joj daje slobodu u radu i napredovanju, a rad sa studentima smatra vrlo inspirativnim i zanimljivim. Kao član studentske organizacije FONIS, na različitim pozicijama, uvek je u toku sa aktuelnim studentskim temama i dešavanjima. Kao naučnika, interesuju je teme iz oblasti pametnih sistema i okruženja, kao i sve prateće tehnologije. U slobodno vreme, interesuje je poezija, ples i gejming."
    );
    setPom(4);
    setOpenModal(true);
    setTimeout(function () {
      window.scrollBy(0, 800);
    }, 2);
  };
  const Panelista5 = function () {
    if (window.innerWidth < 769) {
      if (display5) {
        document
          .getElementsByClassName("Panelista")[5]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[5]
          .classList.toggle("pnl_show");
        display5 = false;
      } else {
        document
          .getElementsByClassName("Panelista")[5]
          .classList.toggle("pnl_show");
        document
          .getElementsByClassName("OPanelistiTelefon")[5]
          .classList.toggle("pnl_show");
        display5 = true;
      }
      return;
    }

    if (pom == 5) {
      setPom(0);
      setOpenModal(false);
      return;
    }
    setSlika(Mirilo);
    setIme("Nikola Mirilo");
    setOpis(
      'Nikola je diplomirani inženjer organizacionih nauka na smeru Operacioni menadžment Fakulteta organizacionih nauka. Zaposlen je u kompaniji Adacta kao Junior IT projektni menadžer/Scrum master na projektima u DACH regiji. Sa strane radi kao freelancee full stack Next.js web developer. Programiranjem se bavi već četiri godine od čega dve i po godine koristi tehnologje React.js i Next.js. Bio je predavač na prethodne dve iteracije S2S-a na temu "React.js from Zero to Hero" i "React at its finest". Pored navedenog bavi se blockchain-om, SEO-om, automatizacijom korišćenjem Python-a i Excel-a i drži časove iz programiranja.'
    );
    setPom(5);
    setOpenModal(true);
    setTimeout(function () {
      window.scrollBy(0, 800);
    }, 2);
  };
  return (
    <div className="panel">
      <div className="panel-meni">
        <Meni />
      </div>
      <div className="panel-hero">
        <div className="pnl_slikagore">
          <img src={Zavesa} />
          <div className="pnl_header">
            <h1>PANEL</h1>
          </div>
        </div>
        <div id="pnl_id">
          <div className="pnl_naziv">
            <h1>Budućnost obrazovanja i poslovanja</h1>
            <p>14. OKTOBAR</p>
          </div>
          <div className="pnl_sredina">
            <div className="pnl_sredinaSlika">
              <img src={S1} className="S1" />
              <img src={S3} className="S3" />
            </div>
            <p>
              Na panel diskusiji ćeš imati priliku da se informišeš o promenama
              koje napredne tehnologije donose u oblasti obrazovanja i
              poslovanja. Otkrićemo kako veštačka inteligencija postavlja novi
              standard za učenje i na koji način možeš kontrolisati njeno
              korišćenje. Takođe, panelisti će sa tobom podeliti kako različite
              inovacije doprinose vođenju poslovnih operacija i stvaranju
              efikasnog radnog i obrazovnog okruženja. Saznaćeš koji su ključni
              izazovi i mogućnosti koje ovi tehnološki trendovi donose. Pozivamo
              te da nam se pridružiš da zajedno istražimo delotvorne načine za
              razvoj i oblikovanje budućnosti uz pomoć novih tehnologija.
            </p>
            <img src={S2} className="S1" />
            <img src={S4} className="S3" />
          </div>
          <div className="pnl_dodatni_holder">
            <h1 className="pnl_naslov_panelista">MODERATOR</h1>

            <Panelista
              funkcija={Moderator}
              slika={Ognjen}
              ime="Ognjen Nikolić"
            />
            <OPanelistiTelefon
              slika={Ognjen}
              ime="Ognjen Nikolić"
              opis="Ognjen Nikolić je saradnik u nastavi na Katedri za operaciona istraživanja na Fakultetu organizacionih nauka. Još od druge godine srednje škole prenos znanja je bila tema koja ga je jako inspirisala, zbog čega je shvatio da je to posao kojim bi želeo da se bavi. Tada je krenuo da radi kao vršnjački edukator, da bi to nastavio kroz rad u studentskim organizacijama i mentorskim programima i na kraju završio kao saradnik u nastavi. Samim tim, projekat Studenti studentima ga je uvek zanimao i jako ga podržava, zato što smatra da ima veliki potencijal za razvoj studenata. Pored rada u nastavi, imao je priliku da radi u prodaji, organizaciji događaja i kao IT projektni menadžer i drago mu je što će zajedno sa panelistima imati prilike da studentima odgovori na pitanja i pomogne im kako u profesionalnom tako i u privatnom razvoju."
              funkcija={Moderator}
            />
            <OPanelisti
              open={openModalModerator}
              ime="Ognjen Nikolić"
              opis="Ognjen Nikolić je saradnik u nastavi na Katedri za operaciona istraživanja na Fakultetu organizacionih nauka.
               Još od druge godine srednje škole prenos znanja je bila tema koja ga je jako inspirisala, zbog čega je shvatio da je to posao kojim bi želeo da se bavi. Tada je krenuo da radi kao vršnjački edukator, da bi to nastavio kroz rad u studentskim organizacijama i mentorskim programima i na kraju završio kao saradnik u nastavi. 
              Samim tim, projekat Studenti studentima ga je uvek zanimao i jako ga podržava, zato što smatra da ima veliki potencijal za razvoj studenata. Pored rada u nastavi, imao je priliku da radi u prodaji, organizaciji događaja i kao IT projektni menadžer i drago mu je što će zajedno sa panelistima imati prilike da studentima odgovori na pitanja i pomogne im kako u profesionalnom tako i u privatnom razvoju."
              slika={Ognjen}
            />
            <h1 className="pnl_naslov_panelista">PANELISTI</h1>
            <div>
              <div className="pnl_clanovi">
                <div className="pnl_ikonice">
                  <Panelista
                    funkcija={Panelista1}
                    slika={Tatjana}
                    ime="Tatjana Stojanović"
                  />
                  <OPanelistiTelefon
                    slika={Tatjana}
                    ime="Tatjana Stojanović"
                    opis="Tatjana Stojanović je asistentkinja na Katedri za softversko inženjerstvo na Fakultetu organizacionih nauka. Pored rada u nastavi, autor je više od 20 naučnih radova i učesnik različitih nacionalnih i međunarodnih projekata. Oblasti interesovanja su joj softverske arhitekture i paterni sa fokusom na .NET tehnologije."
                    funkcija={Panelista1}
                  />
                  <Panelista
                    funkcija={Panelista2}
                    slika={Soske}
                    ime="Uroš Šošević"
                  />
                  <OPanelistiTelefon
                    slika={Soske}
                    ime="Uroš Šošević"
                    opis="Uroš Šošević je rođen 1987. godine, diplomirao je na Fakultetu organizacionih nauka gde je odbranio i doktorsku disertaciju pod nazivom “Softverski okvir za razvoj multimodalnih biometrijskih sistema“ u septembru 2022. godine. Uroš je docent na Fakultetu organizacionih nauka, ali i suosnivač i CTO “Unique Venture Clubs” – platforme za veb3 investitore, zajednice i osnivače. Takođe je bio suosnivač i Blockchain arhitekta u “Blinking” – kompaniji za razvoj softvera sa fokusom i stručnošću u izgradnji i razvoju digitalnog identiteta, verifikacije identiteta i zaštite podataka korisnika."
                    funkcija={Panelista2}
                  />
                  <Panelista
                    funkcija={Panelista3}
                    slika={Boris}
                    ime="Boris Vajagić"
                  />
                  <OPanelistiTelefon
                    slika={Boris}
                    ime="Boris Vajagić"
                    opis="Boris je apsolvent na Fakultetu organizacionih nauka i menadžer platforme FONBoard. Tokom svog studiranja fokus je usmerio na razvoj u oblasti menadžmenta u IT-ju kroz obavljanje različitih uloga - predsednika i člana Upravnog odbora za upravljanje projektima u Udruženju studenata informatike FONIS, studenta demonstratora na Katedri za elektronsko poslovanje i praktikanta na poziciji Product Management Intern u kompaniji Seven Bridges. U junu 2023. godine zajedno sa timom predstavljao je Srbiju na Međunarodnom takmičenju u upravljanju projektima održanom u Valensiji, Španija. Smatra da se mnogi aspekti našeg života mogu posmatrati kao projekti kojima se može pristupiti agilno."
                    funkcija={Panelista3}
                  />
                  <Panelista
                    funkcija={Panelista4}
                    slika={Tamara}
                    ime="Tamara Naumović"
                  />
                  <OPanelistiTelefon
                    slika={Tamara}
                    ime="Tamara Naumović"
                    opis="Tamara Naumović je asistent na Katedri za elektronsko poslovanje, Fakulteta organizacionih nauka. Svoju karijeru u IT-ju je započela u privredi na poziciji junior ITSM inženjera, da bi nakon upisa master akademskih studija 2017. godine, odlučila da svoju karijeru nastavi na Fakultetu organizacionih nauka. Nauka ju je oduvek interesovala i smatra da joj daje slobodu u radu i napredovanju, a rad sa studentima smatra vrlo inspirativnim i zanimljivim. Kao član studentske organizacije FONIS, na različitim pozicijama, uvek je u toku sa aktuelnim studentskim temama i dešavanjima. Kao naučnika, interesuju je teme iz oblasti pametnih sistema i okruženja, kao i sve prateće tehnologije. U slobodno vreme, interesuje je poezija, ples i gejming.
                    "
                    funkcija={Panelista4}
                  />
                  <Panelista
                    funkcija={Panelista5}
                    slika={Mirilo}
                    ime="Nikola Mirilo"
                  />
                  <OPanelistiTelefon
                    slika={Mirilo}
                    ime="Nikola Mirilo"
                    opis='Nikola je diplomirani inženjer organizacionih nauka na smeru Operacioni menadžment Fakulteta organizacionih nauka. Zaposlen je u kompaniji Adacta kao Junior IT projektni menadžer/Scrum master na projektima u DACH regiji. Sa strane radi kao freelancee full stack Next.js web developer. Programiranjem se bavi već četiri godine od čega dve i po godine koristi tehnologje React.js i Next.js. Bio je predavač na prethodne dve iteracije S2S-a na temu "React.js from Zero to Hero" i "React at its finest". Pored navedenog bavi se blockchain-om, SEO-om, automatizacijom korišćenjem Python-a i Excel-a i drži časove iz programiranja.'
                    funkcija={Panelista5}
                  />
                </div>

                <OPanelisti
                  open={openModal}
                  ime={ime}
                  opis={opis}
                  slika={slika}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pnl_donjaSlika">
          <img src={Donja} />
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Panel;
