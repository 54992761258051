import React from "react";
import "./Panelista.scss";
import OPanelistiTelefon from "./OPanelistiTelefon.jsx";
const Panelista = ({ slika, ime, kompanija, funkcija }) => {
  return (
    <div className="Panelista pnl_show" onClick={funkcija}>
      <img src={slika} />
      <p>{ime}</p>
      <p>{kompanija}</p>

      {/* <OPanelistiTelefon ime={ime} slika={slika} kompanija={kompanija} /> */}
    </div>
  );
};

export default Panelista;
